import { React, Link } from 'react';
import { urls } from "./labels.js";
import changeLanguage from "../../imgs/aboutAnna/changeLanguage.png"
import folderExample from "../../imgs/aboutAnna/folderExample.png"
import fileUpload from "../../imgs/aboutAnna/fileUpload.gif"
import imgUpload from "../../imgs/aboutAnna/imgUpload.gif"
import audioUpload from "../../imgs/aboutAnna/audioUpload.png"
import mindMap from "../../imgs/aboutAnna/mindmap.png"


const getText = () => {
    return {
        "SECONDS": (params) => {
            const { value = 0 } = params;
            return `${value} sec`;
        },
        "GENERIC_ERROR": (params) => {
            const { error = "Generic error GE_001" } = params;
            return `Abbiamo avuto un problema durante l'operazione: ${error}`;
        },
        "USER_EXISTS": () => {
            return "Questo indirizzo e-mail è già stato utilizzato!";
        },
        "MISSING_FIELDS": () => {
            return "Compila tutti i campi!";
        },
        "HOME_WELCOME_MESSAGE": (params) => {
            return (<span>
                Benvenuto nell'innovativo tool per i tuoi studi
            </span>);
        },
        "EMAIL_RESENT": () => {
            return "Email rispedita";
        },
        "LIBRARY_WELCOME_MESSAGE": (params) => {
            const { user, docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 0) {
                    return (<span>Ciao {user.name}, ci sono  {number} documenti nella tua libreria</span>);
                }
                else {
                    return (<span>Ciao {user.name}, hai {number} documento nella tua libreria</span>);
                }
            }
            else {
                return (<span>Non ci sono documenti nella tua libreria</span>);
            }


        },
        "YOU_NEED_TO_LOGIN_FIRST": () => {
            return "Devi prima effettuare il login";
        },
        "ACCEPT": () => {
            return `Accetta`;
        },
        "CANCELLED": () => {
            return "Annullato"
        },
        "DECLINE": () => {
            return `Rifiuta`;
        },
        "REMOVE": () => {
            return `Rimuovi`;
        },
        "CLASSROOM_JOINED": () => {
            return `Hai accettato l'invito correttamente`;
        },
        "INVITATION_DECLINED": () => {
            return `Invito rifiutato`;
        },
        "LIBRARY_WELCOME_MESSAGE_CLASS": (params) => {
            const { classroom, docs, folders } = params;
            const number = (docs?.length || 0) + (folders?.length || 0);

            if (number > 0) {
                if (number > 1) {
                    return (<span>Nella classe {classroom?.name}, ci sono  {number} elementi nella libreria</span>);
                }
                else {
                    return (<span>Nella classe {classroom?.name}, hai {number} elemento nella libreria</span>);
                }
            }
            else {
                return (<span>Nella classe {classroom?.name},la libreria è vuota</span>);
            }

        },
        "LIBRARY_WELCOME_MESSAGE_2": (params) => {
            return (<span>
                I tuoi documenti? Li elaboro io, <strong>ANNA</strong>! Ti preparo un riassunto, rispondo a tutte le tue domande e, se vuoi, posso creare anche test per mettere alla prova le tue conoscenze.
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_3": (params) => {
            return (<span>
                Caricate le immagini dalla vostra galleria e io le convertirò in un documento PDF. Sarà letto dal mio motore <strong>AI</strong> che produrrà
                per voi brevi riassunti, risposte a tutte le domande e, se volete, potrei preparare un
                preparare un test per verificare il vostro stato di conoscenza.
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_4": (params) => {
            return (<span>
                Based on your audio input I will create a new book to the library. It will be read by my <strong>AI</strong> engine producing
                for you short summaries, answer to any questions and if you want I could
                prepare a test to verify your state of knowledge.
            </span>);
        },

        "DOCUMENT_WELCOME_MESSAGE": (params) => {
            const { linkTo } = params;
            return (<span>
                Here you can choose the AI session type for the selected document or you can <Link to={linkTo}>go back to the list</Link>.
            </span>);
        },
        "DOCUMENT_QA_SESSION_TITLE": (params) => {

            return "Q&A";
        },
        "DOCUMENT_QA_SESSION": (params) => {

            return (<span>
                Possiamo parlare di qualisasi cosa contenuta nel documento. Puoi farmi delle domande e io ti fornirò le risposte
            </span>);
        },
        "DOCUMENT_QA_SESSION_FOLDER": (params) => {

            return (<span>
                Mi contatti per chiedere qualsiasi cosa sui documenti contenuti in questa cartella. Le darò tutte le risposte di cui ha bisogno.
            </span>);
        },
        "TRAINING_SESSION_TITLE": (params) => {

            return (<span>
                Test
            </span>);
        },
        "TRAINING_SESSION": (params) => {

            return (<span>
                Metti alla prova le tue conoscenze. Posso preparare per te un test e correggerti se le tue risposte dovessero essere sbagliate
            </span>);
        },
        "TRAINING_SESSION_FOLDER": (params) => {

            return (<span>
                Mettete alla prova le vostre conoscenze. Vi offrirò una simulazione di esame di tutti i documenti contenuti in questa cartella e vi suggerirò dove trovare le soluzioni giuste in caso di errore.
            </span>);
        },
        "MIND_MAP_SESSION_TITLE": (params) => {

            return "Mappa logica";
        },
        "MIND_MAP_SESSION": (params) => {

            return (<span>
                Posso creare una mappa mentale del tuo documento per aiutarti a riflettere, assimilare contenuti e a ricordarli meglio.
            </span>);
        },
        "MIND_MAP_SESSION_FOLDER": (params) => {

            return (<span>
                Posso prepare per te una mappa mentale dei documenti per aiutarti a riflettere, assimilare i contenuti e a ricordarli meglio
            </span>);
        },
        "SUMMARY_SESSION_TITLE": (params) => {

            return (<span>
                Riassunto  &amp; traduzione
            </span>);
        },
        "SUMMARY_SESSION": (params) => {

            return (<span>
                Posso farti un riassunto e tradurti il documento
            </span>);
        },
        "GO": (params) => {

            return (<span>
                Go
            </span>);
        },
        "TRY_IT": (params) => {

            return (<span>
                Provami
            </span>);
        },
        "CREATE_FOLDER": () => {
            return "Crea una cartella";
        },
        "FOLDER_NAME": () => {
            return "Nome della Cartella";
        },
        "MUST_INSERT_CLASSROOM_NAME": () => {
            return "E' necessario inserire il nome della classe";
        },
        "MANAGE_YOUR_CLASS": () => {
            return "Organizza le tue classi";
        },
        "INVITE_PROFESSORS": () => {
            return "Invita un professore";
        },
        "TELL_ME_MORE": (params) => {

            return (<span>
                Dimmi di più
            </span>);
        },
        "SEARCH_MEMBERS": () => {
            return `Ricerca utenti`;
        },
        "SEARCH_DOCUMENTS": () => {
            return `Ricerca documenti`;
        },
        "ASK_ME": (params) => {
            return (<span>
                Chiedimi qualcosa
            </span>);
        },
        "MEMBERS": () => {
            return `Membri`;
        },
        "MEMBER": () => {
            return `Membro`;
        },
        "GO_TO_PAGE": () => {
            return "Vai alla pagina ";
        },
        "PREVIOUS": () => {
            return "Precedente";
        },
        "PREVIOUS_PAGES": () => {
            return "Pagine precedenti";
        },
        "NEXT_PAGES": () => {
            return "Pagine successive";
        },
        "NEXT": () => {
            return "Avanti";
        },
        "DELETE": () => {
            return "Elimina";
        },
        "INVITE_PROFESSOR": () => {
            return `Invita un professore`;
        },
        "INSERT_EMAIL": () => {
            return `Inserisci e-mail`;
        },
        "INVITATION": () => {
            return `Invito`;
        },
        "JOIN_NEW_CLASSROOM": () => {
            return "Richiedi l'accesso in una nuova classe";
        },
        "YOU_CANNOT_ENTER_THIS_CLASS": () => {
            return "Devi aspettare che il professore accetti il tuo invitio per poter entrare nella classe!";
        },
        "YOU_ALREADY_ADDED_THIS_CLASS": () => {
            return "L'utente esiste già in questa classe!";
        },
        "WAITING_FOR_APPROVAL": () => {
            return "In attesa di approvazione...";
        },
        "MY_CLASSESS": () => {
            return "Le mie classi";
        },
        "ADD_CLASSROOM": () => {
            return "Aggiungi classe";
        },
        "ENTER_CODE": () => {
            return "Inserire il codice della classe nella quale vuoi entrare";
        },
        "ENTER_CLASS": () => {
            return "Richiedi l'accesso";
        },
        "EDIT": () => {
            return "Modifica";
        },
        "DOWNLOAD_PDF": (params) => {
            return (<span>
                Scarica il Pdf
            </span>);
        },
        "CLASS_NAME": (params) => {
            const { name } = params;

            return (<span>
                Modifica {name}
            </span>);
        },
        "RETURN_TO_DOC": () => {

            return "Indietro";
        },
        "DOCUMENT_QA_SESSION_INTRO": (params) => {

            return (<span>
                Ho letto il documento e ora posso rispondere alle tue domande, quindi cosa aspetti a chiedermi qualcosa?
            </span>);
        },
        "DOCUMENT_UPLOADED": (params) => {
            const { date } = params;

            return (<span>
                Caricato il : {date.toLocaleDateString()}.
            </span>);
        },
        "FOLDER_CREATED": (params) => {
            const { date } = params;

            return (<span>
                Creato il: {date.toLocaleDateString()}.
            </span>);
        },
        "PAGES_LIST": (params) => {
            const { pages = [] } = params;

            return pages.length > 1 ? "Pagine" : "Pagina"
        },
        "PAGES_LIST_WITHPAGE": (params) => {
            const { pages = [] } = params;

            var pageNumbers = pages ? pages.join(",") : "-"

            return (<span>{pages?.length > 1 ? "Pagine" : "Pagina"}: {pageNumbers}</span>)

                ;
        },
        "SHARE_RESULT": () => {
            return "Condividi i risultati";
        },
        "CONTEXT_HELP": () => {
            return "Suggerimento: ";
        },
        "ANSWER": () => {
            return "Risposta: ";
        },
        "ANSWERES_CHECKED": () => {
            return `Abbiamo controllato le tue risposte!`;
        },
        "CHECKING_ANSWERS": () => {
            return "Sto controllando le risposte";
        },
        "DOWNLOAD": () => {
            return "Scarica la mind map";
        },
        "COPY_TO_CLIPBOARD": () => {
            return "Copia negli appunti";
        },
        "COPY_SUMMARY": () => {
            return "Copy";
        },
        "DELETE_QUESTION": () => {
            return "Cancella il documento";
        },
        "PREPARING_PACKAGE": () => {
            return "Preparazione del test e delle domande....";
        },
        "PACKAGE_NUMBER": (params) => {
            const { number } = params;
            return `Test #${number}`;
        },
        "PACKAGE": () => {
            return `Test `;
        },
        "PACKAGE_PROCCESS_FINISH": () => {
            return "Il tuo test è pronto!";
        },
        "PACKAGE_PROCCESS_FINISH_LESS_QUESTIONS": () => {
            //return "Could not generate requested amount of questions. Document is too small.";
            return "Impossibile generare il numero di domande richiesto. Il documento è troppo corto.";
        },
        "PREPARING_DOCUMENT": () => {
            return "Sto elaborando il tuo documento";
        },
        "DOCUMENT_NUMBER": (params) => {
            const { number } = params;
            return `Documento #${number}`;
        },
        "DOCUMENT_PROCCESS_FINISH": () => {
            return "Ho elaborato tutte le informazioni presenti nel documento!";
        },
        "DOCUMENT_NAME_MINDMAP": (params) => {
            const { title } = params;
            return `${title}`;
        },
        "PREPARING_MINDMAP": () => {
            return "Sto preparando la mind map...";
        },

        //#region breadcrumb
        "BREADCRUMB_HOME": () => {
            return "Home";
        },
        "BREADCRUMB_DOCUMENT": (params) => {
            const { name } = params;
            return `${name}`;
        },
        "BREADCRUMB_QA": () => {
            return "Q & A";
        },
        "BREADCRUMB_TRAINING": () => {
            return "Test";
        },
        "BREADCRUMB_SURVEY": () => {
            return "Questionario";
        },
        "BREADCRUMB_SURVEY_ID": (params) => {
            const { id } = params;
            return `${id}`;
        },
        "BREADCRUMB_MINDMAP": () => {
            return "Mappa logica";
        },
        "BREADCRUMB_SUBMINDMAP": () => {
            return "Mappa secondaria";
        },

        //#endregion

        "ADD": () => {
            return "Aggiungi ";
        },
        "DOCUMENT_FOLDERS": () => {
            return "Documenti e cartelle nella classe";
        },
        "ABOUT_CLASS": () => {
            return "Informazioni sulla classe";
        },


        "ADD_DOCUMENT": () => {
            return "Aggiungi un nuovo documento";
        },
        "ADD_FOLDER": () => {
            return "Aggiuni una nuova cartella";
        },
        "CLASSROOM_PROFESSORS": () => {
            return "Professori che sono nella classe";
        },
        "CLASSROOM_STUDENTS": () => {
            return "Studenti che sono nella classe o che hanno richiesto l'accesso";
        },
        "CREATED_BY": () => {
            return "creato da:";
        },
        "ADD_TO_FOLDER": () => {
            return "Aggiungi alla cartella";
        },
        "DELETE_FOLDER": () => {
            return "Cancella la cartella";
        },
        "UPLOAD_NEW_FILE": () => {
            return "Aggiungi un nuovo documento";
        },

        "UPLOAD_NEW_FILE_SMALL": () => {
            return "Se vuoi puoi caricare un nuovo documento anche qui";
        },

        "BOOKLIST_SMALL_MESSAGE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Caricato il  ${uploadDate} alle ${uploadTime}`;
        },

        "YOUR_LIBRARY": () => {
            return "LA TUA LIBRERIA";
        },
        "CAMERA_UPLOAD": () => {
            return "Caricare dalla fotocamera";
        },
        "VOICE_UPLOAD": () => {
            return "Speech to text upload";
        },
        "LOGIN_CREDENTIAL": () => {
            return "Inserisci le tue credenziali";
        },
        "EMAIL_ADDRESS": () => {
            return "E-Mail";
        },
        "PASSWORD": () => {
            return "Password";
        },
        "CONFIRM_PASSWORD": () => {
            return "Confermare la password";
        },
        "REMEMBER_ME": () => {
            return "Ricordami";
        },
        "CHOOSE": () => {
            return `Scegli il tuo paese`;
        },
        "NO_OPTIONS": () => {
            return `Nessun risultato`;
        },
        "ACCEPT_T&C": () => {
            return `Accettare i termini e le condizioni!`;
        },
        "PASSWORDS_NO_MATCH": () => {
            return `Le password non corrispondono!`;
        },
        "PLEASE_FILL_IN_THE_FIELDS": () => {
            return `Compilare tutti i campi!`;
        },
        "NOT_GOOD_EMAIL": () => {
            return `Inserisci un'email valida!`;
        },
        "FORGOT_PASSWORD": () => {
            return "Password dimenticata?";
        },
        "WHO_ARE_YOU": () => {
            return "Chi sei?";
        },
        "OPEN": () => {
            return "Apri";
        },

        "GENERAL_INFO": () => {
            return "Informazioni generali";
        },
        "BREADCRUMB_CLASSROOMS": () => {
            return "Classi";
        },
        "CLASSROOM_DELETE_ERROR": () => {
            return `Ci sono stati dei problemi durante l'eliminazione della classe`;
        },

        "PAGES": (params) => {
            const { pages } = params;
            if (pages === 0) {
                return `Non ci sono pagine nel documento`

            }
            else if (pages === 1) {
                return `${pages} pagina`

            }
            else {
                return `${pages} pagine`

            }
        },

        "CREATE_TEST": () => {
            return "Crea un nuovo test";
        },
        "NO_TEST_TO_SHOW": () => {
            return "Non ci sono test da visualizzare";
        },
        "CHECK_TERMS_CONDITIONS_SOCIAL": () => {
            return "Se ti registri con i social sei d'accordo con  ";
        },
        "CHECK_TERMS_CONDITIONS1": () => {
            return "Sono d'accordo con ";
        },
        "CHECK_TERMS_CONDITIONS2": () => {
            return "Termini e condizioni";
        },
        "OLD_PASSWORD": () => {
            return "Vecchia password";
        },
        "NEW_PASSWORD": () => {
            return "Nuova password";
        },
        "ENTER_OLD_PASSWORD": () => {
            return "Inserisci la tua vecchia password";
        },
        "ENTER_NEW_PASSWORD": () => {
            return "Inserisci la tua nuova password";
        },
        "CHANGE_PASSWORD": () => {
            return "Cambia la password";
        },
        "TEST_PAGE_LABELS": (params) => {
            const { user, questionsPackages } = params;
            if (questionsPackages > 0) {
                if (questionsPackages === 1) {
                    return `${user}, abbiamo un questionario per questo documento`
                }
                else {
                    return `${user}, abbiamo ${questionsPackages} questionari per questo documento`
                }
            }
            else {
                return `${user}, non abbiamo ancora nessun questionario per questo documento`

            }
        },

        "QUESTIONS_COUNT": () => {
            return `Numero di domande`;
        },
        "WAIT": () => {
            return "Attendere";
        },
        "EXPORT_DOCX": () => {
            return "Esportazione in docx";
        },
        "EXPORT_PPTX": () => {
            return "Esportazione in pptx";
        },
        "ERROR": () => {
            return "Errore";
        },
        "ERROR_SENDING_MAIL": () => {
            return `Abbiamo avuto un problema durante l'invio della mail riprova più tardi`;
        },
        "ERROR_INSERT_VALID_QUESTION": () => {
            return "Inserire una domanda valida";
        },
        "ERROR_WHILE_CONNECTING_TO_SERVER": () => {
            return "Si è verificato un errore nella connessione al server. Riprova più tardi...";
        },
        "ERROR_WHILE_CREATING_MINDMAP": () => {
            return "Errore durante la creazione della mappa logica";
        },
        "FOLDER_EXPLANATION": (params) => {
            return (<span>
                Qui puoi vedere tutti i documenti disponibili. I documenti con il flag attivo sono già dentro la cartella.
                Ricordati che puoi aggiundere anche altri documenti

            </span>);
        },
        "WRONG_CREDENTIALS": () => {
            return "Credenziali sbagliate";
        },
        "I_DONT_KNOW": () => {
            return "Non lo so";
        },
        "TEXT_COPIED_TO_CLIPBOARD": () => {
            return "Testo copiato negli appunti!";
        },
        "LOADING": () => {
            return "Caricamento...";
        },
        "LEAVE_MESSAGE_HERE": () => {
            return "Chiedimi quello che vuoi";
        },
        "GROUP_NAME": () => {
            return `Nome del gruppo`;
        },
        "DELETING_DOCUMENT": () => {
            return "Cancellazione del documento";
        },
        "SHOW_MORE": () => {
            return "Mostra di più";
        },
        "SHOW_LESS": () => {
            return "Mostra meno";
        },
        "NO_ELEMENTS_TO_SHOW": () => {
            return "Nessun elemento da mostrare";
        },
        "DO_YOU_WANT_TO_DELETE": () => {
            return "Volete davvero eliminare definitivamente ";
        },
        "CREATE_GROUP": () => {
            return `Creare un gruppo`;
        },
        "OTP_MODAL_BODY": () => {
            return "Controllate la vostra casella di posta elettronica, dove abbiamo inviato il codice di conferma.";
        },

        "OTP_MODAL_TITLE": () => {
            return "Conferma l'e-mail";
        },
        "OTP_MODAL_FIRST_PART_RESEND": () => {
            return "Non hai ricevuto il codice? Clicca ";
        },
        "OTP_MODAL_SECOND_PART_RESEND": () => {
            return "qui ";
        },
        "OTP_MODAL_THIRD_PART_RESEND": () => {
            return "per inviare nuovamente l'e-mail. ";
        },
        "DONE": () => {
            return "Fatto";
        },
        "COLOR": () => {
            return `Scegli un colore`;
        },
        "CREATE_CLASSROOM": () => {
            return `Crea una nuova classe`;
        },
        "ADD_NEW_CLASSROOM": () => {
            return `Aggiungi una classe`;
        },
        "ADD_NEW_GROUP": () => {
            return `Aggiungi un nuovo gruppo`;
        },
        "CLASSROOM_NAME": () => {
            return `Nome della classe`;
        },
        "TEST_DIFFICULTY": () => {
            return `Difficoltà del test`;
        },
        "ANSWERS_TYPE": () => {
            return `Tipo di domande`;
        },
        "CLOSE_ANSWERS": () => {
            return `Domande chiuse`;
        },
        "OPEN_ANSWERS": () => {
            return `Domande aperte`;
        },
        "EASY_QUESTIONS": () => {
            return `Facile`;
        },
        "MEDIUM_QUESTIONS": () => {
            return `Media`;
        },
        "HARD_QUESTIONS": () => {
            return `Difficile`;
        },
        "EXTREME_QUESTIONS": () => {
            return `Estrema`;
        },
        "ANSWERS_QUANTITY": () => {
            return `Tipologia di risposte`;
        },

        "PASSWORD_REQ": () => {
            return "La tua password non rispetta i requisiti";
        },
        "CLOSE": () => {
            return `Chiudi`;
        },
        "TEST_SMALL_MESSAGE": (params) => {
            const { generatedDate, generatedTime } = params;

            return `Generato il  ${generatedDate} alle ${generatedTime}`;
        },
        "SINGLE_CHOICES": () => {
            return "Scelta singola";
        },
        "MULTIPLE_CHOICES": () => {
            return "Scelta Multipla";
        },
        "NQUESTIONS": (params) => {
            const { numbers } = params;

            return `${numbers} domande`;
        },
        "PROGRESS": () => {
            return `Avanzamento`;
        },
        "INDEXING_PROGRESS": () => {
            return "Stato dell'indicizzazione:";
        },
        "WAITING_FOR_DATA": () => {
            return "In attesa di dati...";
        },
        "SUBMIT_BUTTON": () => {
            return `Invia`;
        },
        "CREATE_PDF": () => {
            return `Creare PDF`;
        },
        "FACEBOOK_LOGIN": () => {
            return " Login con Facebook";
        },
        "GOOGLE_LOGIN": () => {
            return " Login con Google";
        },
        "LOGIN": () => {
            return "Login";
        },
        "ARTIFICIAL_INTELLIGENCE_FOR_STUDIES": () => {
            return "Intelligenza artificiale per i tuoi studi";
        },
        "START_NOW": () => {
            return "Inizia ora";
        },
        "REGISTER": () => {
            return "Registrati";
        },
        "REGISTER_CREDENTIAL": () => {
            return "Inserire le proprie credenziali";
        },
        "ERROR_DURING_CREATION": () => {
            return "Errore durante la creazione dell'account!";
        },
        "WRONG_CODE": () => {
            return "Codice di conferma errato.";
        },
        "USER_CREATED": () => {
            return "Il vostro account è stato creato e attivato con successo!";
        },
        "NAME": () => {
            return "Nome";
        },
        "SCHOOL_NAME": () => {
            return "Nome della scuola";
        },
        "LAST_NAME": () => {
            return "Cognome";
        },
        "USERNAME": () => {
            return "Nome utente";
        },
        "DATE_OF_BIRTH": () => {
            return "Data di nascita";
        },
        "SELECT_COUNTRY": () => {
            return "Selezionare il paese";
        },
        "CHECK_TERMS_CONDITIONS": () => {
            return "Verificare se si accettano i termini e le condizioni";
        },
        "PROBLEM": () => {
            return "We have some problems";
        },
        "FACEBOOK_PROBLEM": () => {
            return "Abbiamo dei problemi con il login di Facebook";
        },
        "GOOGLE_PROBLEM": () => {
            return "Abbiamo alcuni problemi con il login di google....Qualcosa non funziona";
        },
        "SELECTED_COLOR": () => {
            return "Seleziona un colore:";
        },
        "CLASSROOM_ADDED_ERROR": () => {
            return `Problema durante la creazione della classe`;
        },
        "CLASSROOM_ADDED": () => {
            return `Classe creata correttamente`;
        },
        "DRAG_DROP_FILES": () => {
            return "Trascinare e rilasciare i documento";
        },
        "NO_FILES_SELECTED": () => {
            return "Nessun documento attualmente selezionato per il caricamento";
        },
        "LANGUAGE_MODAL_LABEL": () => {
            return "Seleziona la lingua";
        },
        "CLASSROOM_DELETE": () => {
            return `Classe cancellata correttamente`;
        },
        "MENU_USER_LOGGED": (params) => {
            const { upperCaseLanguage, user, section } = params;
            let classRoomLink = null;

            if (user) {
                classRoomLink = user.isConfirmedProfessor ? `/it/${"professor"}/classrooms` : "/it/classrooms";
            }

            window.$("#navbarCollapse").html(`
                <div class="navbar-nav ms-auto Text500__5">
                <a href="/it" class="nav-item nav-link ${(section === "library" ? "active" : "")}">Documenti</a>
                <a href="${classRoomLink}" class="nav-item nav-link ${(section === "classrooms" ? "active" : "")}">Classi</a>
                <a href="/it/aboutANNA" class="nav-item nav-link" ${(section === "classrooms" ? "active" : "")}>About ANNA</a>
                <a href="/it${urls.account}/" class="nav-item nav-link ${(section === "settings" ? "active" : "")}">Account</span>
                <strong><a href="/it${urls.logout}" class="nav-item nav-link">Esci</a></strong>
                ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
                
              </div>`);

        },
        "MENU_USER_NOT_LOGGED": (params) => {
            const { upperCaseLanguage } = params;
            window.$("#navbarCollapse").html(`
                <div class="navbar-nav ms-auto Text500__5">
                <a href="/it" class="nav-item nav-link active">Home</a>
                <a href="/it/aboutANNA" class="nav-item nav-link">About ANNA</a>
                <a href="/it/contactus" class="nav-item nav-link">Contatti</a>
                <strong><a href="/it/login" class="nav-item nav-link" id="LoginButtonNav">Accedi</a></strong>
                 ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
                 <a href="/it/register" id="try-button-menu" class="then-small-button ml-5">Prova GRATIS</a>

            </div>`);

        },
        "FOOTER_BOTTOM_MENU_LOGGED": () => {
            window.$("#rightsReserved").html(`
             
                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, Tutti i diritti riservati.
          
            `);
            window.$("#secondFooterColumn").html(`
                                <div class="linkRowFooter">
                                    <a href="/it" class="linkFooter">La mia libreria</a>
                                    <a href="/it/aboutANNA" class="linkFooter" target='_blank' rel="noopener noreferrer">Chi siamo</a>
                                    <a href="/it/contactus" class="linkFooter">Contatti</a>
                                    <a href="/it/terms_and_conditions" class="linkFooter">Termini & Condizioni</a>
                                    <a href="/it/privacy_policy" class="linkFooter">Privacy</a>
                                    <a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>
                                </div>  
            `);
        },
        "FOOTER_BOTTOM_MENU_NOT_LOGGED": () => {
            window.$("#rightsReserved").html(`
             
                                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, Tutti i diritti riservati.
                          
            `);
            window.$("#secondFooterColumn").html(`
                 <div class="linkRowFooter">
                                    <a href="/it/aboutANNA" class="linkFooter" target='_blank' rel="noopener noreferrer">Chi siamo</a>
                                    <a href="/it/contactus" class="linkFooter">Contatti</a>
                                    <a href="/it/terms_and_conditions" class="linkFooter">Termini & Condizioni</a>
                                    <a href="/it/privacy_policy" class="linkFooter">Privacy</a>
                                    <a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>
                                </div>  
            
            `);
        },
        // "GET_IN_TOUCH_FOOTER": () => {
        //     window.$("#getInTouch").html(`
        //         <h5 class="text-white mb-4">Rimani in contatto con noi</h5>
        //             <p><i class="fa fa-map-marker-alt me-3"></i>Lvivo g. 25, Vilnius 09320, Lithuania</p>
        //             <p><i class="fa fa-phone-alt me-3"></i>+012 345 67890</p>
        //             <p><i class="fa fa-envelope me-3"></i>info@lab-h9.com</p>
        //             <div class="d-flex pt-2">
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>s
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-instagram"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
        //             </div>

        //     `);
        // },
        "ADD_TO_CLASSROOM": () => {
            return `Condividi con una classe`;
        },
        "CREATE_CARD": () => {
            return `Crea una nuova classe`;
        },
        "FOLDER_CREATION_DATE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Created in  ${uploadDate} at ${uploadTime}`;
        },
        "CREATE_YOUR_IDENTITY": () => {
            return "Crea il tuo account gratuito";
        },
        "PASSWORD_REQUIREMENTS": () => {
            window.$("#password-Req").html(`
              <div className="password-requirements" id="password-Req">
                                        <p>La password deve soddisfare i seguenti requisiti:</p>
                                        <ul>
                                            <li>Almeno 8 caratteri</li>
                                            <li>Contiene almeno una lettera maiuscola</li>
                                            <li>Contiene almeno una lettera minuscola</li>
                                            <li>Contiene almeno un numero</li>
                                            <li>Contiene almeno un carattere speciale</li>
                                        </ul>
                                    </div>
            
            `);
        },
        "ENTER_NAME": () => {
            return "Inserisci il tuo nome";
        },
        "ENTER_EMAIL": () => {
            return "Inserisci la tua e-mail";
        },
        "ENTER_LASTNAME": () => {
            return "Inserisci il tuo cognome";
        },
        "ENTER_PASSWORD": () => {
            return "Inserisci la tua password";
        },
        "ENTER_BIRTHDATE": () => {
            return "Inserisci la tua data di nascita";
        },
        "ENTER_COUNTRY": () => {
            return "Inserisci il tuo Paese";
        },
        "ENTER_CONFIRM_PASSWORD": () => {
            return "Conferma la password";
        },
        "ENTER_SCHOOL": () => {
            return "Inserisci la tua scuola";
        },
        "PASS_REQS": () => {
            return "La password deve avere questi requisiti:";
        },
        "PASS_REQS1": () => {
            return "Deve avere almeno 8 caratteri";
        },
        "PASS_REQS2": () => {
            return "Deve contenere almeno una lettera maiuscola";
        },
        "PASS_REQS3": () => {
            return "Deve contenere almeno una lettera minuscola";
        },
        "PASS_REQS4": () => {
            return "Deve contenere almeno un numero";
        },
        "PASS_REQS5": () => {
            return "Deve contenere almeno un carattere speciale";
        },

        "WHOAREYOU_MODAL": () => {
            return "Come vuoi registrarti?"
        },
        "NORMAL_USER": () => {
            return "Utente"
        },
        "USER_PROFILE": () => {
            return "Il tuo profilo"
        },
        "PROFESSOR_USER": () => {
            return "Professore"
        },

        "ADD_MODAL_ELEMENT": () => {
            return "Cosa vuoi aggiungere?"
        },
        "UPLOAD": () => {
            return "Caricare "
        },
        "CLEAR": () => {
            return "Cancella "
        },
        "PRESS_MIC_TO_UPLOAD": () => {
            return "Premi il microfono per caricare"
        },
        "UPLOAD_TRANSCRIBE": () => {
            return "Carica e trascrivi "
        },
        "OR": () => {
            return "o"
        },
        "CLASSROOM": () => {
            return "Classe";
        },
        "YOU_HAVE_BEEN_INVITED_IN_CLASS": (params) => {
            const { classroom } = params;

            return `Hai ricevuto un invito per classe  ${classroom}`;
        },
        "REMOVE_STUDENT_HEADER": () => {
            return `Rimuovi lo studente`;
        },
        "REMOVE_STUDENT_BODY": () => {
            return `Stai per rimuovere lo studente dalla classe, sei sicuro di voler procedere?`;
        },
        "AUDIO_EXPLANATION": () => {
            return "Premi il tasto per avviare la registrazione e rilascialo quando vuoi procedere con l'invio"
        },
        "AUDIO_FILE_PROGRESS": () => {
            return "Sto analizando l'audio..."
        },
        "REMOVE_STUDENT_BUTTON": () => {
            return "Rimuovi lo studente";
        },
        "STUDENT_DELETE_DONE": () => {
            return "Studente rimosso";
        },
        "STUDENT_DELETE_SUCCESSFULLY": () => {
            return "Studente rimosso dalla classe con successo";
        },
        "STUDENT_DELETE_PROBLEM": () => {
            return "Ci sono stati dei problemi durante la cancellazione, l'utente non è stato rimosso";
        },
        "NO_CLASS_FOR_CODE": () => {
            return "Non abbiamo trovato nessuna classe con questo codice";
        },
        "USER_JUST_IN_CLASSROOM": () => {
            return "Sei già dentro la classe o sei in attesa che il professore accetti la tua richiesta";
        },
        "MISSING_CODE": () => {
            return "Devi inserire il codice per poter inviare la richiesta";
        },
        "DOCUMENT_NAME": () => {
            return "Nome del documento";
        },
        "UPLOADED_IN": () => {
            return "Data caricamento";
        },
        "ELEMENT_TYPE": () => {
            return "Tipo";
        },
        "PROFESSOR_NAME": () => {
            return "Professore";
        },
        "EMAIL_PROFESSOR": () => {
            return "E-mail";
        },
        "MOBILE_PHONE": () => {
            return "Telefono";
        },
        "PROFESSOR_ACTION": () => {
            return "Elimina";
        },
        "REMOVE_PROFESSOR_HEADER": () => {
            return `Rimuovi il professore`;
        },
        "REMOVE_PROFESSOR_BODY": () => {
            return `Stai per rimuovere il professore dalla classe. Sei sicuro di voler continuare?`;
        },
        "REMOVE_PROFESSOR_BUTTON": () => {
            return `Rimuovi`;
        },
        "PROFESSOR_DELETE_DONE": () => {
            return "Professore rimosso";
        },
        "PROFESSOR_DELETE_SUCCESSFULLY": () => {
            return "Professore rimosso dalla classe con successo";
        },
        "PROFESSOR_DELETE_PROBLEM": () => {
            return "Ci sono stati dei problemi durante la cancellazione, l'utente non è stato rimosso";
        },
        "GO_TO_LIBRARY": () => {
            return "Documenti";
        },
        "CLASSROOM_CODE_LABEL_PROFESSOR": (params) => {
            const { code } = params;
            return (<span>
                Codice identificativo della classe: <strong>{code ?? ""}</strong>
            </span>);
        },
        "NO_DOCUMENTS_GRID": () => {
            return ("Nessun documento nella classe");
        },
        "NO_PROFESSOR_GRID": () => {
            return ("Nessun professore nella classe");
        },
        "NO_STUDENT_GRID": () => {
            return ("Nessuno studente nella classe");
        },
        "BOOKS_INSIDE": (params) => {
            const { number } = params;
            if (number > 0) {
                if (number > 1) {
                    return (

                        <small>{`${number} documenti`}</small>)
                }
                else {
                    return (

                        <small>{`${number} documento`}</small>)
                }
            } else {
                return (
                    <small>{`Nessun documento`}</small>)
            }
        },
        "CLASSROOM_LIBRARY": () => {
            return "Documenti della classe";
        },
        "OTP_MODAL_DELETE_TITLE": () => {
            return "Sei sicuro di voler eliminare l'utente?";
        },
        "EDIT_CARD": () => {
            return `Salva`;
        },
        "WELL_DONE": () => {
            return " Ben fatto!";
        },
        "ALL_CORRECT": (params) => {
            const { maxScore } = params;

            return `Complimenti, hai risposto  correttamente a tutte le ${maxScore} domande!`;
        },
        "TOPIC_EXPERT": () => {
            return "Ormai sei un esperto su questo argomento";
        },
        "GOOD_JOB": () => {
            return "Bel lavoro!";
        },
        "SOMEONE_CORRECT": (params) => {
            const { actualScore, maxScore } = params;
            return `Complimenti, hai risposto  correttamente a ${actualScore} su ${maxScore} domande!`;
        },
        "SOMEONE_CORRECT_SAD": (params) => {
            const { actualScore, maxScore } = params;
            return `Peccato, hai risposto  correttamente a ${actualScore} su ${maxScore} domande!`;
        },
        "TEST_TRY_AGAIN": () => {
            return "Controlla le risposte sbagliate e provaci ancora!";
        },
        "STUDY_MORE": () => {
            return "Devi studiare di più!";
        },
        "ANSWER_NOT_SELECTED": () => {
            return "Non hai selezionato nessuna risposta!";
        },
        "YOU_ANSWERED": () => {
            return "La tua risposta";
        },
        "BACK_TO_QUESTIONS": () => {
            return "Ritorna alle domande";
        },

        "LEAVE_CLASSROOM": () => {
            return "Vuoi lasciare la classe?";
        },
        "LEAVE_CLASSROOM_MESSAGE": () => {
            return "Sei sicuro di voler abbandonare la classe?";
        },
        "LEAVE_CLASSROOM_BUTTON": () => {
            return "Lascia la classe";
        },
        "DELETE_ELEMENT_MODAL": () => {
            return "Stai per cancellare un elemento";
        },
        "REMOVE_ELEMENT_BODY_MODAL": (params) => {
            const { elementName } = params
            return `Stai per rimuovere definitivamente ${elementName} dalla tua libreria.
             Questa azione è irreversibile, sei sicuro di voler procedere?`;
        },
        "REMOVE_ELEMENT_BUTTON": () => {
            return "Elimina";
        },
        "USER_PROFILE_SELECT_DESCRIPTION": () => {
            return "Visualizzare e gestire i dettagli del proprio profilo";
        },
        "PAYMENT_DETAILS": () => {
            return "Dettagli dei pagamenti";
        },
        "PAYMENT_DETAILS_DESCRIPTION": () => {
            return "Visualizzare la cronologia dei pagamenti e tutti i dettagli relativi";
        },
        "SUBSCRIPTION_DETAILS": () => {
            return "Abbonamento";
        },
        "SUBSCRIPTION_DETAILS_DESCRIPTION": () => {
            return "Visualizza tutti i dettagli del tuo abbonamento";
        },
        "USER_PROFILE_SELECT": () => {
            return "Impostazioni del profilo utente";
        },
        "ENTER_BUTTON": () => {
            return "Vai";
        },
        "ACCOUNT_DETAILS": () => {
            return "Dettagli del tuo profilo";
        },
        "ACCOUNT_DETAILS_DESC": () => {
            return "Qui è possibile visualizzare i dettagli del proprio account. È inoltre possibile modificarli.";
        },
        "USER_SETTINGS": () => {
            return "Impostazioni utente";
        },
        "USER_SETTINGS_DESC": () => {
            return "Qui è possibile visualizzare tutte le informazioni relative al proprio account e ai propri abbonamenti.";
        },
        "SUBSCRIPTION_SETTINGS": () => {
            return "Dettagli dell'abbonamento";
        },
        "SUBSCRIPTION_SETTINGS_DESC": () => {
            return "Qui è possibile visualizzare tutti i dettagli relativi all'abbonamento: contatori, date e altro ancora!"
        },
        "CANCEL": () => {
            return "Disdici"
        },
        "DELETE_ACCOUNT": () => {
            return "Cancellare l'account"
        },
        "FIRST_NAME": () => {
            return "Nome"
        },
        "ACCOUNT_TYPE": (params) => {
            var { accountType } = params;
            if (accountType === "Student") {
                accountType = "Studente";
            }
            else if (accountType === "Professor") {
                accountType = "Professore";
            }
            return `${accountType}`;
        },
        "SAVE": () => {
            return "Salva"
        },
        "ACCOUNT_TYPE_LABEL": () => {
            return "Tipo di account"
        },
        "DELETE_ACCOUNT_BODY": () => {
            return "Sei sicuro di voler cancellare il tuo account? Questa azione cancellerà anche tutti i documenti, i pacchetti di domande e le mappe mentali caricati!"
        },
        "DELETE_ACCOUNT_TITLE": () => {
            return "Sei sicuro?"
        },
        "YES": () => {
            return "Sì"
        },
        "NO": () => {
            return "No"
        },
        "TO_CHANGE_EMAIL": () => {
            return "Per modificare l'e-mail, contattare l'assistenza!"
        },
        "SUBSCRIPTION_PLAN": () => {
            return "Piano di abbonamento:"
        },
        "QUESTIONS_ASKED": () => {
            return "Domande effettuate:"
        },
        "TESTS_GENERATED": () => {
            return "Test generati:"
        },
        "MINUTES_OF_SPEECH": () => {
            return "Minuti usati per la registrazione:"
        },
        "PAGES_UPLOADED": () => {
            return "Pagine dei documenti caricate:"
        },
        "SUBSCRIPTION_EXPIRES": () => {
            return "Il tuo abbonamento scadrà:"
        },
        "COUNTER_RESET": () => {
            return "I contatori saranno azzerati il:"
        },
        "NO_DATA_AVAILABLE": () => {
            return "Nessun dato disponibile"
        },
        "ORDER": () => {
            return "ACQUISTA"
        },
        "WEEKLY": () => {
            return "Settimanale"
        },
        "MONTHLY": () => {
            return "Mensile"
        },
        "YEARLY": () => {
            return "Annuale"
        },
        "DURATION_SUB_MONTHLY_PLURAL": (params) => {
            var { expirationUnit } = params;
            if (expirationUnit === "year") {
                expirationUnit = "anni";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "month") {
                expirationUnit = "mesi";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "week") {
                expirationUnit = "settimane";
                return `${expirationUnit}`;
            }
            return `${expirationUnit}`;
        },

        "DURATION_SUB": (params) => {
            var { expirationUnit } = params;
            if (expirationUnit === "year") {
                expirationUnit = "anno";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "month") {
                expirationUnit = "mese";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "week") {
                expirationUnit = "settimana";
                return `${expirationUnit}`;
            }
            return `${expirationUnit}`;
        },
        "CATALOGUE": () => {
            return `Catalogo in abbonamento`;
        },
        "CATALOGUE_HEADER": () => {
            return `Selezionare l'abbonamento`;
        },
        "CHECKOUT_HEADER": () => {
            return `Cassa dell'abbonamento`;
        },
        "SELECT_METHOD": () => {
            return `Selezionare il metodo di pagamento qui sotto:`;
        },
        "PROCEED_CHECKOUT": () => {
            return `Procedere alla cassa`;
        },
        "LOGIN_PROCEED": () => {
            return `Per procedere con il checkout è necessario essere registrati!`;
        },
        "DONT_HAVE_ACC": () => {
            return `Non hai un account?`;
        },
        "DONT_HAVE_ACC2": () => {
            return `Iscriviti!`;
        },
        "SUBSCRIPTION_SUCCESSFUL": () => {
            return "Abbonamento riuscito!";
        },
        "SUBSCRIPTION_ERROR": () => {
            return "Abbonamento non riuscito!";
        },
        "LIBRARY_WELCOME_MESSAGE_NUMEBR": (params) => {
            const { docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 1) {
                    return (<span> Ci sono  {number} documenti nella tua libreria</span>);
                }
                else {
                    return (<span> C'è  {number} documento nella tua libreria</span>);
                }
            }
            else {
                return (<span>La tua libreria è vuota</span>);
            }
        },
        "WIP": () => {
            return `Lavorazione...`;
        },
        "PAYMENT_DETAILS_DESC": () => {
            return "Qui puoi vedere e controllare i tuoi pagamenti";
        },
        "PAYPAL_CHECKOUT_ERROR": () => {
            return "Errore durante il processo di pagamento, riprovare!";
        },
        "SUBSCRIPTION_IN_PROGRESS": () => {
            return "Attendere, elaborazione dell'abbonamento...";
        },
        "LANGUAGE_NOT_FOUND": () => {
            return "Lingua non trovata";
        },
        "NO_PAYMENTS": () => {
            return "Nessun pagamento trovato";
        },
        "RETURN_TO_TEST": () => {
            return "Ritorno al test";
        },
        "TRAINING_HEADER": (params) => {
            const { type } = params;
            if (type === "document") {
                return "I TEST PER IL TUO DOCUMENTO";
            }
            if (type === "folder") {
                return "TEST PER LA CARTELLA";
            }
        },
        "FILE_TYPE_NOT_SUPPORTED": () => {
            return "Tipo di file non supportato";
        },
        "CHANGE": () => {
            return "Cambia";
        },
        "COUNTRY": () => {
            return "Paese";
        },
        "Q&A_HEADER": () => {
            return "Chiedi e ti rispondo";
        },
        "QUESTION": () => {
            return "DOMANDE";
        },
        "QUESTION_TYPES": () => {
            return "Tipologia di domande";
        },
        "QUESTION_NUMBER": () => {
            return "Numero di domande";
        },
        "QUESTION_CHOICE": () => {
            return "Numero di scelte";
        },
        "YOUR_ANSWER": () => {
            return "LA TUA RISPOSTA";
        },
        "NOT_ANSWERED": () => {
            return "La domanda non ha avuto risposta";
        },
        "CORRECT_ANSWER": () => {
            return "RISPOSTA CORRETTA";
        },
        "CORRECT_ANSWER_SMALL": () => {
            return "Risposta corretta";
        },
        "PAGES_ANSWER": () => {
            return "Pagine: ";
        },
        "QUESTIONS": () => {
            return "domande";
        },
        "RETURN_TO_TEST_NEW": () => {
            return "Ripetere il test";
        },
        "DOCUMENT_PAGE_HEADER": () => {
            return "DAI UN BOOST AL TUO DOCUMENTO";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS": () => {
            return "LA COMPAGNA DI STUDI IDEALE";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS_ANNA": () => {
            return "Con ANNA hai tutto quello che ti serve per eccellere nello studio e raggiungere più velocemente i tuoi obiettivi. È la tutor che ti fa interagire con i tuoi libri e documenti, sfruttando al massimo il tuo tempo.";
        },
        "TRY_ANNA": () => {
            return "Prova ANNA gratis";
        },
        "USED_BY_STUDENTS": () => {
            return "usato da + 10.000 studenti";
        },
        "THEY_TALKED_ABOUT_US": () => {
            return "Hanno già parlato di noi";
        },
        "PAGE_SELECTOR": () => {
            return "Seleziona le pagine";
        },
        "PAGES_RANGE_HELP": () => {
            return "Inserire un numero di pagina (es. 5), un intervallo di pagine (es. 5-10), un elenco di pagine (es. 1, 2, 3, 4, 5) o un mix (es. 5, 7-10, 12, 14).";
        },
        "PAGES_RANGE": () => {
            return "Scegliere le pagine";
        },
        "SAVE_CHANGES": () => {
            return "Salva le modifiche";
        },
        "BOOST_STUDY_WITH_AI": () => {
            return "Potenzia il tuo studio con l’aiuto dell’ai";
        },
        "LANDING_FUNCTIONS_DESC": () => {
            return "ANNA rende lo studio divertente ed interattivo. Ecco le tre funzionalità principali che ti aiuteranno a studiare meglio:";
        },
        "LANDING_FUNCTIONS_DESC_CARD_1": () => {
            return (<>Chatta con <strong>ANNA</strong> chiedile qualsiasi cosa riguardo ai documenti che carichi. Lei ha sempre la risposta che cerchi!</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_2": () => {
            return (<>Crea una <strong>mappa mentale</strong> a partire da qualsiasi documento. Visualizza le informazioni in modo chiaro e organizzato per facilitare il tuo apprendimento</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_3": () => {
            return (<><strong>Metti alla prova le tue conoscenze</strong> con le simulazioni di esame di ANNA. Ottieni feedback subito e scopri dove migliorare, così sarai sempre pronto per i test veri.</>);
        },
        "LANDING_STUDENTS_TITLE": () => {
            return (<>Perché gli Studenti Amano ANNA</>);
        },
        "LANDING_STUDENTS_SUBTITLE": () => {
            return (<>Scopri un nuovo modo di studiare con ANNA! Grazie ai nostri strumenti basati sull’AI, potrai:</>);
        },
        "LANDING_STUDENTS_LIST_1": () => {
            return (<>Creare mappe mentali che semplificano i concetti complessi.</>);
        },
        "LANDING_STUDENTS_LIST_2": () => {
            return (<>Chiedere qualsiasi cosa sul materiale di studio e ottenere risposte immediate.</>);
        },
        "LANDING_STUDENTS_LIST_3": () => {
            return (<>Simulare esami, compiti in classe, interrogazioni o semplicemente identificare dove puoi migliorarti.</>);
        },
        "LANDING_STUDENTS_LIST_4": () => {
            return (<>Caricate e imparate da libri, documenti, registrazioni audio e video, immagini e altro ancora!</>);
        },
        "LANDING_STICKER_TEACHER_TEXT": () => {
            return "per i docenti";
        },
        "LANDING_STICKER_STUDENT_TEXT": () => {
            return "Per gli studenti";
        },
        "LANDING_TEACHER_TITLE": () => {
            return (<>Docenti, ecco il Vostro Superpotere</>);
        },
        "LANDING_TEACHER_SUBTITLE": () => {
            return (<>ANNA non è solo un aiuto per gli studenti, ma anche un prezioso alleato per i docenti. Con i nostri strumenti potrai:</>);
        },
        "LANDING_TEACHER_LIST_1": () => {
            return (<>Caricare materiali didattici e creare attività interattive in pochi clic.</>);
        },
        "LANDING_TEACHER_LIST_2": () => {
            return (<>Monitorare i progressi degli studenti e fornire feedback personalizzati.</>);
        },
        "LANDING_TEACHER_LIST_3": () => {
            return (<>Risparmiare tempo nella preparazione delle lezioni grazie alle funzioni di automazione.</>);
        },
        "LANDING_SUBSCRIPTIONS_TITLE": () => {
            return (<>Studia Smart senza Sfondare il Portafoglio!</>);
        },
        "LANDING_SUBSCRIPTIONS_SUBTITLE": () => {
            return (<>Con ANNA, studiare smart è alla portata di tutti. Scopri i nostri piani accessibili e trova quello perfetto per te!</>);
        },
        "LANDING_QUESTIONS_TITLE": () => {
            return (<>Domande? Noi Abbiamo le Risposte!</>);
        },
        "LANDING_QUESTIONS_DESCRIPTION": () => {
            return (<>Se hai domande, sei nel posto giusto! Abbiamo raccolto le risposte alle domande più comuni per aiutarti a sfruttare al meglio ANNA. Dai un’occhiata qui sotto e scopri come Anna può rendere il tuo studio ancora più efficace e divertente!</>);
        },

        "LANDING_QUESTIONS_LIST_0": () => {
            return (<>Come funziona l'AI di ANNA e in cosa mi può aiutare?</>);
        },
        "LANDING_QUESTIONS_LIST_1": () => {
            return (<>È sicuro usare ANNA e quali documenti posso caricare?</>);
        },
        "LANDING_QUESTIONS_LIST_2": () => {
            return (<>L'AI può sostituire un insegnante? È adatta a tutti i tipi di esami e scuole?</>);
        },


        "LANDING_QUESTIONS_LIST_0_ANSWER": () => {
            return (<>L'AI di ANNA analizza i documenti che carichi (libri, appunti, slide, dispense) e risponde a
                qualsiasi domanda tu possa avere riguardo concetti, date, definizioni e dettagli specifici. Ti aiuta
                a capire meglio il materiale di studio e organizza le informazioni chiave in mappe concettuali per
                facilitare la comprensione e la memorizzazione. Puoi anche creare quiz e test personalizzati,
                scegliendo il numero di domande, il tipo di risposta (aperta o chiusa), e ottenere correzioni
                dettagliate per migliorare la tua preparazione.</>);
        },
        "LANDING_QUESTIONS_LIST_1_ANSWER": () => {
            return (<>Puoi caricare libri, appunti, slide, dispense o altri materiali di studio in formato digitale. La
                sicurezza dei tuoi dati è una priorità: i tuoi documenti sono trattati in modo confidenziale e non
                condivisi con terze parti. Utilizziamo misure avanzate di sicurezza per proteggere le tue
                informazioni, e non sono necessarie competenze tecniche per usare il tool: è semplice e
                intuitivo.</>);
        },
        "LANDING_QUESTIONS_LIST_2_ANSWER": () => {
            return (<>L'AI è uno strumento di supporto allo studio, pensato per potenziare il tuo metodo di
                apprendimento, chiarendo dubbi, riassumendo concetti e simulando test. Tuttavia, non
                sostituisce il valore di un insegnante. È versatile e adatta a prepararti per qualsiasi tipo di
                esame, sia scritto che orale, aiutandoti a ottimizzare il tuo studio e la tua preparazione in modo
                efficace.</>)
        },


        "CHOOSE_PLAN": () => {
            return (<>Scegli il piano</>);
        },
        "LANDING_MESSAGES_TITLE": () => {
            return (<>Perți Tutti Parlano di ANNA</>);
        },
        "LANDING_MESSAGES_QUOTE1": () => {
            return (<>ANNA ha trasformato il mio modo di studiare! Le mappe mentali e i quiz mi aiutano a capire meglio i concetti e a prepararmi per gli esami in modo più efficace. Non potrei più farne a meno!</>);
        },
        "LANDING_MESSAGES_QUOTE1_AUTHOR": () => {
            return (<>Marco, 16 anni</>);
        },
        "LANDING_MESSAGES_QUOTE2": () => {
            return (<>Grazie ad ANNA, posso caricare i materiali didattici e creare attività interattive in pochi minuti. Monitorare i progressi degli studenti è diventato molto più semplice, e il feedback personalizzato ha migliorato notevolmente il loro apprendimento</>);
        },
        "LANDING_MESSAGES_QUOTE2_AUTHOR": () => {
            return (<>Prof.ssa Rossi, Insegnante di Matematica</>);
        },
        "STUDENT": () => {
            return (<>Studente</>);
        },
        "TEACHER": () => {
            return (<>Docente</>);
        },
        "DAILY": () => {
            return (<>Giornaliero</>);
        },

        "PRIVACY_POLICY_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["PRIVACY_POLICY"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["PRIVACY_POLICY"]()}:</h3>
                    <p className='Text500__6'>UAB "Lab-H9" (hereinafter - the Data Controller) in this privacy policy (hereinafter - the Privacy Policy) sets the conditions for processing personal data using
                        this website managed by the Data Controller. The terms set out in the Privacy Policy apply every time you access the content and/or services we provide, regardless of the device (computer, mobile phone, tablet, TV, etc.) you are using.
                    </p>
                    <p className='Text500__7'>Details of the data controller:
                    </p>
                    <p className='Text500__7'>UAB "Lab-H9"
                    </p>
                    <p className='Text500__7'>Office address: Laisvės al. 82,
                    </p>
                    <p className='Text500__7'>Kaunas, LT-44250 Lietuva
                    </p>
                    <p className='Text500__7'>E-mail: <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>
                    </p>
                    <p className='Text500__6'>It is very important that you read this Privacy Policy carefully, because every time you visit the <a href="https://www.labh9.ai" target='_blank' rel="noopener noreferrer">www.labh9.ai</a> website, you agree to the terms described in this Privacy Policy. If you do not agree to these terms, we regret to ask you not to visit our website, use our content and/or services. We confirm that the data of visitors to the website of the Data Controller will be collected in accordance with the requirements of the applicable legal acts of the European Union and the Republic of Lithuania and the instructions of the controlling authorities. All reasonable technical and administrative measures are in place to protect the data we collect about website visitors from loss, unauthorized use or alteration.
                        Individuals who are under the age of 16 may not submit any personal data through our website. If you are under the age of 16, you must obtain the consent of your parent or other legal guardian before submitting personal information.
                        The terms used in this privacy policy are understood as they are defined in the General Data Protection Regulation no. 2016/679.
                    </p>
                    <ul className='Text500__6'>
                        <li>name;
                        </li>
                        <li>surname;
                        </li>
                        <li>e-mail address.
                        </li>
                    </ul>
                    <h3 className="test-boost">Browsing data</h3>
                    <p>
                        We use local storage to manage security (at logon time) and to remember Your preferred language.
                    </p>
                    <p>
                        We will not share this information with anyone and it will only be stored on Your browser.
                    </p>
                    <p>
                        For access with third-party authentication systems (single-sign-on), such as Google and Facebook, it is possible that these systems use cookies and local storage and we refer You to the respective policies.
                    </p>
                    <ul className="Text500__5" style={{ textAlign: 'left' }}>
                        <li>
                            <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                                Google Cookie Policy
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                                Facebook Cookie Policy
                            </a>
                        </li>
                    </ul>
                    <p>
                        To remind us that You have read the cookies policy, we will store a cookie on Your browser, which we will not share with anyone and which will only serve to prevent this message from appearing automatically every time You connect to the ANNA web.
                    </p>
                    <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                    <table className='cookies-table Text500__5 mb-3 mx-auto'>
                        <tbody>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Purpose</th>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>cookieConsent</td>
                                <td>Remembering that You’ve read this cookie page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>_grecaptcha</td>
                                <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>UserAuth</td>
                                <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>active_language</td>
                                <td>Used to remember Your preferred language for the user interface</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 className="test-boost">Third party cookies</h3>
                    <p>
                        The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                        <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                    </p>
                    <p>
                        If you accept third party cookies please refer to this providers and privacy disclosures policies:
                    </p>
                    <ul>
                        <li>Google Analytics
                            <ul>
                                <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                                <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                            </ul>
                        </li>
                        <li>Google TAG manager
                            <ul>
                                <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                            </ul>
                        </li>
                        <li>META PIXEL
                            <ul>
                                <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                            </ul>
                        </li>
                    </ul>
                    <h3 className="test-boost">How we use your information</h3>
                    <p className='Text500__6'>We may process your personal data for the following purposes:
                    </p>
                    <ul className='Text500__6'>
                        <li>For the purpose of direct marketing*, send newsletters, provide individually tailored advertising and send messages, evaluate and analyze our market, customers, products and services (including collecting your opinion about products and services and customer organization of surveys).
                        </li>
                        <li>To administer the website;
                        </li>
                        <li>To improve your browsing experience by adapting this website to your personal needs;
                        </li>
                        <li>In other ways with your consent*
                        </li>
                        <p className='Text500__6'><i>*We inform you that you have the right to disagree or withdraw your consent at any time to process your data for the above purposes marked with an asterisk.</i>
                        </p>
                    </ul>
                    <h3 className="test-boost">Other information we collect</h3>
                    <p className='Text500__6'>We may also collect other information about you, your device or your use of our website content with your consent.</p>
                    <p className='Text500__6'>We undertake not to transfer your personal data to any unrelated third parties, except in the following cases:
                    </p>
                    <ul className='Text500__6'>
                        <li>If there is consent to the disclosure of personal data.
                        </li>
                        <li>To law enforcement institutions in accordance with the procedure provided by the legal acts of the Republic of Lithuania.
                        </li>
                    </ul>
                    <h3 className="test-boost">Your rights
                    </h3>
                    <ul className='Text500__6'>
                        <li>To know (to be informed) about the processing of your data (right to know);
                        </li>
                        <li>Familiarize yourself with your data and how they are processed (right of access);
                        </li>
                        <li>Demand correction or, taking into account the purposes of personal data processing, to supplement incomplete personal data (right to correction);
                        </li>
                        <li>To destroy your data or stop the processing of your data (except storage) (right to destruction and right to be forgotten);
                        </li>
                        <li>Demand that the Personal Data Controller restricts the processing of personal data for one of the legitimate reasons (right to restriction);
                        </li>
                        <li>You have the right to transfer data (right to transfer);
                        </li>
                        <li>Opt-out to the processing of personal data when this data is processed or intended to be processed for direct marketing purposes, including profiling as far as it relates to such direct marketing.
                        </li>
                    </ul>
                    <p className='Text500__6'>If you no longer want your personal data to be processed for the purpose of direct marketing, you can write to at the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a> and do not agree to your personal data being processed for the purpose of direct marketing.
                        You can submit any request or instruction related to the processing of personal data to the Data Controller by e-mail. to the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>. Upon receiving such a request or instruction, the data controller shall, no later than within one month from the date of the request, provide a response and perform the actions specified in the request or refuse to perform them. If necessary, the specified period can be extended by another two months, depending on the complexity and number of requests. In this case, within one month from the date of receipt of the request, the Data Controller informs the subject of such an extension, together with the reasons for the delay.
                    </p>
                    <h3 className="test-boost">Third-party websites, services and products on our websites
                    </h3>
                    <p className='Text500__6'>The Data Controller's website may contain third-party banners, links to their websites and services over which the Data Controller has no control. The data controller is not responsible for the security and privacy of information collected by third parties. You must read the privacy statements that apply to third-party websites and services you use.
                    </p>

                    <p>If you want to delete all your data you can delete your account in the user profile page, and our system automatically will remove all the personal information</p>

                </div>


            );
        },

        "TERMS_AND_CONDITIONS": () => {
            return "Termini e condizioni"
        },
        "TERMS_AND_CONDITIONS_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}:</h3>
                    <p className='Text500__6'>These Terms of Use apply to the use of the Platform of Lab-H9 (UAB Lab-H9). Please read these Terms of Use carefully in order to ensure that you are aware of your rights and obligations when using the Platform. You can download and print this document.
                    </p>
                    <p className='Text500__6'>These Terms of Use <i>inter alia</i>, describe the following aspects of the relationship between you and Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>The provision of Study Material (article 7) and creating a Profile (article 5);</li>
                        <li>The functionalities of the Platform (articles 3- 6);</li>
                        <li>The use of the AI Summaries, Mindmaps and Q&A functionality (article 8);</li>
                        <li>The use of the Group Chats functionality (article 9);</li>
                        <li>The notice and take down procedure (article 14);</li>
                        <li>The limitation of liability of Lab-H9(article 15).</li>
                    </ul>
                    <h3 className="test-boost">Article 1. Definitions</h3>
                    <p className='Text500__6'>In these Terms of Use, the terms listed below have the following meaning:
                    </p>
                    <p className='Text500__6'>1.1. <strong>AI: </strong>software that uses artificial intelligence techniques to generate Summaries, Mindmaps and answers and tests in reference to documents uploaded by the user                </p>
                    <p className='Text500__6'>1.2. <strong>AI Answers:</strong> all the answers and instructions that AI generates and that are made accessible to the user via dedicated section on the Platform, in response to questions submitted by the user.</p>
                    <p className='Text500__6'>1.3. <strong>AI Q&A:</strong> a separate section on the Platform, which enables User to submit questions in reference to documents earlier uploaded, which are then answered by AI.</p>
                    <p className='Text500__6'>1.4. <strong>Mindmaps:</strong> a separate section on the Platform, which enables User to get a mindmap in reference to documents earlier uploaded, which are generated by AI </p>
                    <p className='Text500__6'>1.5. <strong>Test/Quiz:</strong> a separate section on the Platform, which enables User to test their learing skill to a specific document. All the questions are generated by AI</p>

                    <p className='Text500__6'>1.6. <strong>Information:</strong> all information placed on the Platform by Lab-H9 itself, including – but not limited to – the layout and look and feel of the Platform, the logos and trademarks as well as texts. The Information does not include Study Materials.</p>

                    <p className='Text500__6'>1.7. <strong>Intellectual Property Rights:</strong> any and all intellectual property rights and associated rights such as copyrights, trademark rights, patent rights, model rights, trade name rights, database rights and neighbouring rights as well as the rights to know-how and one-line performances.</p>
                    <p className='Text500__6'>1.8. <strong>Platform:</strong> the online environment developed and offered by Lab-H9, which enables User from around the world to upload their documents and interact with them through an AI model. The Platform contains a separate AI, Summaries, Q&A, mindmap and Test sections  The Platform can be accessed through the websites <a href="https://anna.labh9.eu" target='_blank' rel="noopener noreferrer">anna.labh9.eu;</a> including all underlying domains and web pages, as well as mobile versions of the website and the Lab-H9 application for tablets and smartphones (“App”).
                    </p>
                    <p className='Text500__6'>1.9. <strong>Premium Account: </strong>a subscription that gives User access to the most functionalities on the Platform, as well as to specific features </p>
                    <p className='Text500__6'>1.10. <strong>Profile: </strong>an individual section created and maintained by you when you register for the Platform.</p>
                    <p className='Text500__6'>1.11. <strong>USER: </strong>a User of the Platform.</p>
                    <p className='Text500__6'>1.12. <strong>Documents:</strong> all the study documents, summaries, notes, assignments, books and other materials that are uploaded by User in his own account.</p>
                    <p className='Text500__6'>1.13. <strong>Terms of Use:</strong> these Terms of Use</p>
                    <h3 className="test-boost">Article 2. Applicability</h3>
                    <p className='Text500__6'>2.1. These Terms of Use govern every use of the Platform. By using the Platform, you accept these Terms of Use. The applicability of any other terms and conditions is excluded.</p>
                    <p className='Text500__6'>2.2. Lab-H9 is entitled to amend or supplement these Terms of Use. The most up-to-date Terms of Use can be found on the Platform. In the event of an amendment or supplementation that significantly affect your rights or obligations, Lab-H9 will bring these changes to your attention during the use of the Platform.</p>
                    <p className='Text500__6'>2.3. In the event that you continue the use of the Platform after these Terms of Use have been amended or supplemented, you thereby irrevocably accept the amended or supplemented Terms of Use. If you do not agree with the amended or supplemented Terms of Use, the only option you have is to no longer use the Platform and to terminate your Profile.
                    </p>
                    <h3 className="test-boost">Article 3. Availability</h3>
                    <p className='Text500__6'>3.1. You accept that the Platform contains only the functionalities and other characteristics as found on the Platform at the time of use ("as is basis"). Lab-H9 expressly excludes any explicit and tacit guarantees, undertakings and warranties of any nature whatsoever including, but not limited to, with regard to the quality, safety, lawfulness, integrity and accuracy of the Platform, unless explicitly stated otherwise in these Terms of Use.</p>
                    <p className='Text500__6'>3.2. You accept that the functionalities of the Platform may change.
                    </p>
                    <p className='Text500__6'>3.3. Lab-H9 is entitled to put the Platform (temporarily) out of service and/or to reduce the use of it without any prior notification and without being obliged to pay compensation to you, if in the opinion of Lab-H9 this is necessary, for instance, for maintenance of the Platform.
                    </p>
                    <p className='Text500__6'>3.4. Lab-H9 does not guarantee that the Platform or any part thereof will be accessible at all times and without any interruptions or failures. Failures in the Platform can occur as a result of failures in the internet or phone connection or by viruses or faults/defects. Lab-H9 is not liable or obliged to User to pay compensation in any way whatsoever for any damage resulting or arising from the website being (temporarily) unavailable.
                    </p>
                    <p className='Text500__6'>3.5. Lab-H9 is at any time entitled to change, alter or shut down the Platform without becoming liable to you in any way. If you do not agree with the changes and/or amendments made by Lab-H9, your only remedy is to cease the use of the Platform and to delete your Profile.
                    </p>
                    <h3 className="test-boost">Article 4. The Platform </h3>
                    <p className='Text500__6'>4.1. Lab-H9 offers a Platform where User can upload their Materials , such as documents essay, books and so forth and interact with them using AI. The interaction with the AI generates summaries, mindmaps, Q&A and test  8). Lab-H9’s mission is to empower User to excel at their studies by providing the best tools to learn more efficiently.
                    </p>
                    <p className='Text500__6'>4.2. The usage of the platform is based on a “freemium” model.
                    </p>
                    <p className='Text500__6'>4.3. Some Premium functions are only available for User with a Premium Account. Certain functionalities and features of the Platform require a Premium Account.
                    </p>
                    <p className='Text500__6'>4.4. The Platform may contain links to third party websites that are not owned or controlled by Lab-H9. Lab-H9 has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites.
                    </p>
                    <h3 className="test-boost">Article 5. Profile</h3>
                    <p className='Text500__6'>5.1. In order to access to Premium functionalities you have to create a Profile in the way described on the Platform. When creating a Profile, you must give an email address and password which will allow you to access the Platform and your Profile. You can also register using your existing Facebook or Google-account.
                    </p>
                    <p className='Text500__6'>5.2. You are only allowed to use the Platform when aged sixteen (16) or older. In the event that you have not yet reached the age of sixteen (16), you accept that you need the consent of your legal representative(s) (your guardian or (one of) your parents) before creating a Profile and/or taking out a subscription.

                    </p>
                    <p className='Text500__6'>5.3. You are responsible for maintaining secrecy with regard to your registration details. As soon as you know or have reason to assume that your username and/or password has come into the hands of unauthorized persons, you must inform Lab-H9 of this, without prejudice to your own obligation to take immediate effective measures yourself such as changing your password. You accept and acknowledge that you are at all times responsible and liable for the use of the Platform by third parties via your Profile.
                    </p>
                    <p className='Text500__6'>5.4. The personal data you provide during the registration process will be processed in accordance with Lab-H9's Privacy Policy Statement.
                    </p>
                    <p className='Text500__6'>5.5. You acknowledge and agree that by creating a Profile your user name, university and (if provided) profile picture are visible to other User if you post Study Materials and/or place comments, unless you choose to post Study Materials and/or partake in Study Communities anonymously.
                    </p>
                    <p className='Text500__6'>5.6 You acknowledge and confirm that you have all the rights on the document you upload and interact with on the platform
                    </p>
                    <h3 className="test-boost">Article 6. Premium Account</h3>
                    <p className='Text500__6'>6.1. User can obtain Premium access by taking out a dedicated subscription. The prices are communicated on the Platform.
                    </p>
                    <p className='Text500__6'>6.2. Payment is due in the manner described on the Platform.
                    </p>
                    <p className='Text500__6'>6.3. Lab-H9 offers a 7 days free trial subscription, which allows you to use Premium functionalities available on the Platform.. After 7 days, your trial subscription automatically extends into a weekly full Premium subscription, unless you cancel the subscription prior to that date. You can unsubscribe at any time via your Profile and no deductions will be made. After unsubscribing, you will retain access for the remainder of the trial period.
                    </p>
                    <p className='Text500__6'>6.4. It is possible that Lab-H9 offers longer/shorter free trial subscriptions in specific circumstances, such as actions for study societies. The applicable conditions will then be communicated by Lab-H9.
                    </p>
                    <p className='Text500__6'>6.5. Lab-H9 may introduce or change the subscription fees from time to time, for which we will give you advance notice. If your current subscription is still running, the price will remain in force for the fixed term. If you do not agree with the price change, you must cancel your subscription by the end of the then-current subscription term. If you do not cancel your subscription after the price change goes into effect, you agree to pay the changed price.
                    </p>
                    <p className='Text500__6'>6.6. Lab-H9 may suspend or cancel your Premium Account if a payment is not successfully settled.
                    </p>
                    <p className='Text500__6'>6.7. The subscription period will be renewed automatically for the selected subscription period, unless you have cancelled your subscription on time (before the last day of your subscription). In case your subscription is automatically renewed, you have the right to cancel the subscription at any time after such renewal with a cancellation term of one (1) month. In case of cancellation you may request reimbursement of part of the subscription fee. This means that you pay for the period until the cancellation enters into force, based on the standard monthly fee.
                    </p>
                    <h3 className="test-boost">Article 7. Documents/Materials           </h3>
                    <p className='Text500__6'>7.1. By means of the Platform User can upload their documents and material to be used for the learning process.

                    </p>
                    <p className='Text500__6'>7.2. You have the possibility to create folders, containing Study Materials selected by you relating to a certain course or subject. Folders or single documents are visible only to the User. Nothing will be shared with other users
                    </p>
                    <p className='Text500__6'>7.3. You understand and accept that you are responsible and accountable for Material you upload on the Platform. Lab-H9 is not liable to you or any third party for any damages arising out of or in relation to the Material uploaded or used by you within the Platform.
                    </p>
                    <p className='Text500__6'>7.4. You understand and accept that Lab-H9does not, and is under no obligation to, check or (re)view your Material. Lab-H9 only conducts an automated (quality) check to verify whether the Study Material contains sufficient texts and/or pages. Lab-H9has no knowledge of and/or influence on or involvement with the Materials uploaded by User.
                    </p>
                    <p className='Text500__6'>7.5. Lab-H9 reserves the right to convert the Materials you uploaded to another format and to automatically insert a watermark and/or advertisements in the materials.
                    </p>
                    <p className='Text500__6'>7.6. If you can delete your document/material at any time. The action cannot be reversed.
                    </p>
                    <p className='Text500__6'>7.7. Lab-H9 retains the right to decline or remove Material from the Platform, without accepting any responsibility or liability for its contents, without being held liable for any damage and without being obliged to pay any compensation.
                    </p>
                    <h3 className="test-boost">Article 8. AI Summaries, Mindmap, Q&A and Test/Quiz            </h3>
                    <p className='Text500__6'>8.1. By means of the separate AI Summaries, Mindmap, Q&A and Test/Quiz section on the Platform, User can interact with the uploaded document and answered by AI. The AI Answers will be available for User via a database in specific section on the Platform.
                    </p>
                    <p className='Text500__6'>8.2. To interact with all the sections, you will need to create a Profile.
                    </p>
                    <p className='Text500__6'>8.3. You accept and agree Lab-H9 solely facilitates the functionality, which enables User to use AI for their Studies.
                    </p>
                    <p className='Text500__6'>8.4. The Platform will specify the manner in which User can interact with the AI. Lab-H9 reserves the right to limit the availability of functionalities to User with a Premium Account even with Unlimited access or Unlimited usage. The limit, include – but it is not limited to a slow in AI reply or to a limit the number of questions can be submitted per day (or other time unit).
                    </p>
                    <p className='Text500__6'>8.5. AI will generate feedbacks within seconds. Lab-H9, however, does not guarantee that an the feedbacks are always provided within this time frame and does not accept liability in this regard.
                    </p>
                    <p className='Text500__6'>8.6. User agree that clear questions will generate a better AI output. Lab-H9 is not liable for any incorrect feedback
                    </p>
                    <p className='Text500__6'>8.7. The AI feedbacks are to be used as support in the learning process only, not as a substitute for a traditionl learning course. Lab-H9is not liable for the accuracy or completeness of the AI feedbacks.
                    </p>
                    <p className='Text500__6'>8.8. You understand and accept that you are responsible and accountable for use of feedbacks generated by AI. Lab-H9 is not liable to you or any third party for any damages arising out of, or in relation to them
                    </p>
                    <p className='Text500__6'>8.9. You understand and accept that Lab-H9 does not, and is under no obligation to, check or (re)view the AI feedbacks.
                    </p>
                    <h3 className="test-boost">Article 10. Your obligations and use of the Platform
                    </h3>
                    <p className='Text500__6'>10.1. You will only use the documents/materials that you will upload in the Platform
                    </p>
                    <p className='Text500__6'>10.2. You may not resell, distribute, or in any other way make profit of the Platform.
                    </p>
                    <p className='Text500__6'>10.3. You are the only responsible for documents you upload for instance (e-)books or any other material on the Platform.
                    </p>
                    <p className='Text500__6'>10.4. You are not permitted to use the Platform for a purpose as listed below, which is unrelated to the purpose of Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>as your own work if it is not, as this is in breach of copyright. This is also plagiarism and constitutes academic misconduct at all academic institutions;
                        </li>
                        <li>which, at the discretion of Lab-H9, is discriminating or is otherwise deemed hurtful, offensive or inappropriate;
                        </li>
                        <li>in which personal data of others is made available, other than in compliance with applicable law;
                        </li>
                        <li>which contains scrapers, spiders, viruses, Trojan horses, worms, bots or other software which can damage or delete the Platform or make it unusable or inaccessible, or which or which is intended to circumvent technical protection measures of Lab-H9, the Website and/or the computer systems of Lab-H9;
                        </li>
                        <li>which infringes the rights of Lab-H9 and/or third parties, including but not limited to intellectual property rights or rights concerning the protection of privacy;
                        </li>
                        <li>which qualifies as or transmits unwanted or unsolicited material or content (spam);
                        </li>
                        <li>is contrary to these Terms of Use or any applicable laws or regulations, is in any other manner unlawful or which could prejudice the interests and reputation of lab-H9.
                        </li>
                    </ul>
                    <p className='Text500__6'>10.5. Lab-H9 will not be obliged to perform any payment, taxation, levy or deduction of any nature whatsoever to anyone as a result of the material uploaded by you, including to persons or parties whose work, model, brand or performance is included in the Materials, including to collective rights organizations.
                    </p>
                    <p className='Text500__6'>10.6. Lab-H9 reserves the right, without prior notice, to abridge, alter, refuse and/or remove Materials without this resulting in any liability.
                    </p>
                    <p className='Text500__6'>10.7. Lab-H9 is entitled to (temporarily) restrict or suspend your activities in connection with the Platform and/or to delete your Profile in the event you violate these Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 11. Intellectual property rights
                    </h3>
                    <p className='Text500__6'>11.1. The Intellectual Property Rights in relation to the Platform, including the intellectual property rights with respect to the Information, are held by Lab-H9or its licensors. Nothing in the Terms of Use is intended to entail any transfer of Intellectual Property Rights to you.
                    </p>
                    <p className='Text500__6'>11.2. Under the conditions set out in these Terms of Use, Lab-H9 grants you a limited, personal, revocable, non-exclusive, non-sub licensable and non-transferable right to access and use the Platform, including the Information, insofar as is necessary for the use of the Platform.
                    </p>
                    <p className='Text500__6'>11.3. The collection of Materials or documents, that is collected by Lab-H9 and displayed on the Platform is a legally protected database. Lab-H9 is the producer of the database and therefore has the exclusive right to grant permission for i) the extraction or re-utilization of the entirety or a substantial part, from a quantitative and/or qualitative point of view, of the contents of the database and ii) the repeated and systematic extraction or re-utilization of non-substantial parts, from a quantitative and/or qualitative point of view, of the contents of the database, in so far as this is in conflict with the normal operation of the database or causes unjustified damage to lab-H9’s lawful interests. You may only request or reuse data from the database if and in so far as this is permitted under these Terms and Conditions.
                    </p>
                    <p className='Text500__6'>11.4. You retain all Intellectual Property Rights to your Material.
                    </p>
                    <p className='Text500__6'>11.5. You warrant that there are no (Intellectual Property) Rights of third parties which preclude you from use the Material uploaded on the Platform and/or providing the licence as mentioned in Article 11.4.
                    </p>
                    <p className='Text500__6'>11.6. You shall refrain from any act that infringes the Intellectual Property Rights of Lab-H9, including – but not limited to – the registration of domain names, trademarks or Google Adwords that are identical or similar to any object to which Lab-H9 holds Intellectual Property Rights, as well as the reproduction, modification, reverse engineering or publishing of the Platform, including the Information, for other purposes than those listed in the Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 12. Indemnities and warranties
                    </h3>
                    <p className='Text500__6'>12.1. You guarantee that you will not use the Platform in a way that (i) infringes the rights of Lab-H9 or third parties, including – but not limited to – Intellectual Property Rights or rights in relation to the protection of privacy, (ii) is contrary to any current legislation or regulations, or (iii) is contrary to any provision in these Terms of Use.
                    </p>
                    <p className='Text500__6'>12.2. You are liable and indemnify Lab-H9 against any and all claims of third parties based on the allegation that the Material infringes any Intellectual Property Right or other right of third parties or is otherwise unlawful against third parties as well as against all claims resulting from your actions in violation with these Terms of Use. All costs incurred and damages suffered by Lab-H9 which are in any way related to the abovementioned claims will be remunerated by you.
                    </p>
                    <p className='Text500__6'>12.3. Lab-H9 does not guarantee (i) the Platform’s fitness for a particular purpose or that the Platform shall meet any of your requirements, (ii) that the Platform shall be available to you at all times and without interruptions, limitations or disturbances; (iii) that AI feedbacks on the Platform are accurate, up to date or complete.
                    </p>
                    <h3 className="test-boost">Article 13. Downloading and sharing Study Material
                    </h3>
                    <p className='Text500__6'>13.1. User with a Profile and a Premium Account have the ability to download some Materials from the Platform for personal use only. Your Profile will register what Material you have downloaded.
                    </p>
                    <p className='Text500__6'>13.2. Downloading Material is allowed, provided that you do not violate our Fair Use Policy. This policy states that you can download a maximum of 10 documents per day, with a maximum of 30 documents per month.
                    </p>
                    <p className='Text500__6'>13.3. You accept and acknowledge that sharing Materials generated by the AI is only permitted using a link to the Platform. You are not permitted to share any Materials that you have downloaded by other means that a link.
                    </p>
                    <h3 className="test-boost">Article 14. Notice of alleged unlawful material & repeat infringers
                    </h3>
                    <p className='Text500__6'>14.1. Lab-H9 respects the (Intellectual Property) Rights of others and takes the protection of (Intellectual Property) Rights seriously. Although Lab-H9 is not liable for any damages in connection with the (illegal) use of the Platform by User, Lab-H9 will, under the conditions as set out in this Article, remove Material(s), that is (are) infringing and/or unlawful and stop manifestly unlawful behaviour after receiving a notification that is sufficiently precise and adequately substantiated.
                    </p>
                    <p className='Text500__6'>14.2. If you claim that certain Material AI generated infringe on your (Intellectual Property) Rights, you can report this alleged infringement to Lab-H9 by mail. We will examine the notice and, if possible and necessary, take the measures to clear the issue.
                    </p>
                    <p className='Text500__6'>14.3. Lab-H9 reserves the right to not grant a request to remove Materials, or to stop an activity in case it has reasonable grounds to doubt the accuracy of the notification or in case a balancing of interest requires this. In such a situation, Lab-H9 can require a court order from a competent court, to demonstrate that the material or the activity is manifestly unlawful.
                    </p>
                    <p className='Text500__6'>14.4. Prior to removal of any alleged unlawful Material(s), Lab-H9 may request and the notifying party shall provide, additional information substantiating that the notifying party is indeed the rightsholder, and Lab-H9 may request and the notifying party shall provide, a signed copy of an indemnification declaration for LAB-H9.
                    </p>
                    <p className='Text500__6'>14.5. Lab-H9 will not be a party in a dispute between the person making the report and any third party arising out of or in connection with a notification.
                    </p>
                    <p className='Text500__6'>14.6. Lab-H9 has a policy of terminating accounts of User who repeatedly and intentionally infringe the Intellectual Property Rights of others.
                    </p>
                    <p className='Text500__6'>14.7. The notifying party indemnifies Lab-H9 against all claims of third parties in relation to the blocking or removal of Materials or the stopping of activities as a result of the notification. The indemnification includes all damages and costs Lab-H9 suffers, could suffer or which Lab-H9 must incur in relation to such a claim, including but not limited to the compensation of legal assistance.
                    </p>
                    <p className='Text500__6'>14.8. Lab-H9 respects and protects the privacy of those who provide notifications. All personal data that it receives as part of a notification will only be used to process the notice.
                    </p>
                    <p className='Text500__6'>14.9. Lab-H9 reserves the right to forward the notice to the user responsible for the Material to which the notice refers.
                    </p>
                    <h3 className="test-boost">Article 15. Liability
                    </h3>
                    <p className='Text500__6'>15.1. Lab-H9 accepts no liability whatsoever for damages resulting from the provision of the Platform, including damage resulting from or in connection with the use of the Platform or from wrongful acts or otherwise, in so far as this is permitted pursuant to mandatory law.
                    </p>
                    <p className='Text500__6'>15.2. In the event that Lab-H9– despite the above – is liable, this liability is limited to direct damages the user suffers as a result of a shortcoming or wrongful act that can be attributed to lab-H9. Direct damage only includes material damage to goods, reasonable costs incurred to prevent or limit direct damage and reasonable costs incurred to determine the cause of the damage. Lab-H9 shall in no event be liable for any special, incidental or consequential damages whatsoever (“indirect damages”), including – but not limited to – loss and/or damage of data (including  Material), loss of profits and lost sales, is excluded.
                    </p>
                    <p className='Text500__6'>15.3. Lab-H9’s liability is in any case limited to the highest of the following amounts: (i) the amount actually paid by the user as premium account, or (ii) € 500,00 (five hundred euros/00).
                    </p>
                    <p className='Text500__6'>15.4. The limitations and exclusions of liability mentioned in the preceding paragraphs of this article will lapse if and in so far as the damage is the result of intentional or willful recklessness on the part of Lab-H9 or its managers (“own actions”).
                    </p>
                    <h3 className="test-boost">Article 16. Force Majeure
                    </h3>
                    <p className='Text500__6'>16.1. In the case of force majeure, the non-fulfillment of the agreement between you and Lab-H9cannot be counted as an attributable failure and Lab-H9 is released from all contractual obligations and liability.
                    </p>
                    <p className='Text500__6'>16.2. Force majeure includes, but is not limited to, illness of employees and/or absence of personnel crucial for the provision of the Platform, disruptions of the electricity supply, fire, natural disasters, strikes, floods, failures to perform by third parties hired by Lab-H9, disturbances in the internet connection, hardware disruptions, disruptions in the (telecommunications) networks and other unforeseen circumstances.
                    </p>
                    <h3 className="test-boost">Article 17. Termination
                    </h3>
                    <p className='Text500__6'>17.1. You are entitled to discontinue your use of the Platform and to terminate your Profile.
                    </p>
                    <p className='Text500__6'>17.2. You accept and acknowledge that any Materials uploaded, or any output created and stored, by you beforehand, will be delated from the Platform.
                    </p>
                    <h3 className="test-boost">Article 18. Miscellaneous
                    </h3>
                    <p className='Text500__6'>18.1. These Terms of Use and the use of the Platform are governed by and construed in accordance with the laws of Lithuania
                    </p>
                    <p className='Text500__6'>18.2. Any and all disputes arising from or related to the Platform or the Terms of Use will be brought before the competent court in Vilnius, unless compulsory law mandates otherwise.
                    </p>
                    <p className='Text500__6'>18.3. If at any time any provision of these Terms of Use is or becomes illegal, void or invalid for any reason whatsoever, such invalidity shall not affect the validity of the remainder of the Terms of Use and such invalid provision shall be replaced by another provision which, being valid in all respects, shall have effect as close as possible to that of such replaced one
                    </p>
                    <p className='Text500__6'>18.4. Lab-H9 is entitled to assign any rights and obligations that result from these Terms of Use to third parties, in which case it will notify you. In the event that you cannot agree with the assignment, your only remedy is to cease the use of the Platform and delete your Profile.
                    </p>
                    <br></br>
                </div>
            );
        },
        "PLAN_DOESNT_INCLUDE_FOLDERS": () => {
            return ("Il piano non include le cartelle");
        },
        "PLAN_DOESNT_INCLUDE_VOICE": () => {
            return ("Il piano non include la trascrizione vocale");
        },
        "PLAN_DOESNT_INCLUDE_VIDEO": () => {
            return ("Il piano non include la trascrizione video")
        },
        "SUBSCRIPTION_REQUIRED": () => {
            return ("Dimensione massima dei dati raggiunta!");
        },
        "GO_TO_CATALOGUE": () => {
            return ("Vai al catalogo");
        },
        "PAGES_THUMB_TITLE": () => {
            return "pagina";
        },
        "COUNTRY_NOT_FOUND": () => {
            return `Aggiungi il paese al tuo profilo!`;
        },
        "CONTACT_US": () => {
            return `Contattaci`;
        },
        "WRITE_EMAIL": () => {
            return `E-Mail`;
        },
        "LEAVE_MAIL_MESSAGE_HERE": () => {
            return `Scrivi qui il tuo messaggio`;
        },
        "OPS": () => {
            return `Opssss...`
        },
        "NAME_MANDATORY": () => {
            return `Devi inserire il nome per procedere`
        },
        "SURNAME_MANDATORY": () => {
            return `Devi inserire il cognome per procedere`
        },
        "MAIL_MANDATORY": () => {
            return `Devi inserire la tua mail per procedere`
        },
        "TEXT_MANDATORY": () => {
            return `Non puoi mandare una mail vuota`
        },
        "INVALID_IMAGE": () => {
            return `Sono ammesse solo immagini JPEG e PNG!`;
        },
        "STATUS": () => {
            return `Stato`;
        },
        "SUBSCRIPTION": () => {
            return `Prodotto`;
        },
        "AMOUNT": () => {
            return `Importo`;
        },
        "DATE": () => {
            return `Data`;
        },
        "UPLOAD_DOC_MOBILE_TITLE": () => {
            return `Carica un documento`;
        },
        "UPLOAD_DOC_MOBILE_DESC": () => {
            return `Aggiungi un file pdf, ebook, word, powerpoint, excel o testuale.`;
        },
        "CREATE_FOLDER_MOBILE_TITLE": () => {
            return `Creare una cartella`;
        },
        "CREATE_FOLDER_MOBILE_DESC": () => {
            return `Crea una cartella per organizzare i tuoi documenti`;
        },
        "UPLOAD_VIDEO_MOBILE_TITLE": () => {
            return `Carica un video`;
        },
        "UPLOAD_VIDEO_MOBILE_DESC": () => {
            return `Usa un video live oppure invia un file video`;
        },
        "UPLOAD_URL_MOBILE_TITLE": () => {
            return `Carica una pagina web`;
        },
        "UPLOAD_URL_MOBILE_DESC": () => {
            return `Dammi l'URL della pagina e otterrò tutto il contenuto`;
        },
        "UPLOAD_URL_MOBILE_LABEL": () => {
            return `Indirizzo della pagina da inviare`;
        },
        "UPLOAD_URL_CHECK_MOBILE_LABEL": () => {
            return `Segui i link della pagina`;
        },
        "UPLOAD_URL_MOBILE_MODAL_DESC": () => {
            return `La pagina indicata verrà scaricata e tutto il suo contenuto sarà usato come un documento della tua libreria.`;
        },
        "UPLOAD_URL_MOBILE_DOWNLOADING_DESC": () => {
            return `Sto scaricando la tua pagina. Attendi.`;
        },
        "SUBMIT_PICTURE_MOBILE_TITLE": () => {
            return `Scansiona`;
        },
        "SUBMIT_PICTURE_MOBILE_DESC": () => {
            return `Scansiona il tuo libro o invia immagini.`;
        },
        "UPLOAD_SPEECH_MOBILE_TITLE": () => {
            return `Registra un audio`;
        },
        "UPLOAD_SPEECH_MOBILE_DESC": () => {
            return `Registra un audio `;
        },
        "UPLOAD_SPEECH_DESKTOP_DESC": () => {
            return `Usa la tua voce o invia un file audio.`;
        },
        "COOKIE_POLICY_WELCOME": () => {
            return "Benvenuti ad ANNA";
        },
        "LEARN_MORE": () => {
            return "Per saperne di più";
        },
        "I_UNDERSTAND": () => {
            return "Salva preferenze";
        },
        "NECESSARY_COOKIES": () => {
            return `Cookies necessari`;
        },
        "THIRD_PARTY_COOKIES": () => {
            return `Cookies pubblicitari di terze parti`;
        },
        "COOKIE_POLICY_TEXT": () => {
            return (<div className="gdpr-modal-list Text500__5" style={{ textAlign: 'justify' }}>
                <p>ANNA è realizzato da UAB LAB-H9, Lithuania, Laisvės al. 82, Kaunas,  <a href="mailto:anna@labh9.eu">anna@labh9.eu.</a></p>
                <p>
                    Usiamo local storage per gestire la sicurezza (al momento dell'accesso) e per ricordare la tua lingua preferita.
                </p>
                <p>
                    Noi non condivideremo con alcuni questi dati che saranno memorizzati nel tuo browser.                </p>
                <p>
                    Per l'accesso con sistemi di autenticazione di terze parti (single-sign-on), come Google e FaceBook, è possibile che tali sistemi utilizzino cookie e local storage, per questo ti rimandiamo alle loro rispettive policy.
                </p>
                <ul className="Text500__5" style={{ textAlign: 'left' }}>
                    <li>
                        <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                            Google Cookie Policy
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                            Facebook Cookie Policy
                        </a>
                    </li>
                </ul>
                <p>
                    Noi non gestiremo o memorizzeremo cookie di terze parti ma è possibile che per motivi di sicurezza oppure per motivi legali i token di autenticazione vengano memorizzati nel notro database.
                </p>
                <p>
                    Per ricordare che tu hai già letto questa pagina ed evitare di mostrartela ad ogni accesso utilizzeremo un cookie.
                </p>
            </div>
            )
        },
        "COOKIE_POLICY_TABLE": () => {
            return (<div className="gdpr-more-info Text500__5" style={{ alignItems: 'center' }}>
                <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                <table className='cookies-table Text500__5 mb-3 mx-auto'>
                    <tbody>
                        <tr>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Purpose</th>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>cookieConsent</td>
                            <td>Remembering that You’ve read this cookie page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>_grecaptcha</td>
                            <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>UserAuth</td>
                            <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>active_language</td>
                            <td>Used to remember Your preferred language for the user interface</td>
                        </tr>
                    </tbody>
                </table>
                <h3 className="test-boost">Third party cookies</h3>
                <p>
                    The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                    <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                </p>
                <p>
                    If you accept third party cookies please refer to this providers and privacy disclosures policies:
                </p>
                <ul>
                    <li>Google Analytics
                        <ul>
                            <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                            <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                        </ul>
                    </li>
                    <li>Google TAG manager
                        <ul>
                            <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                        </ul>
                    </li>
                    <li>META PIXEL
                        <ul>
                            <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                        </ul>
                    </li>
                </ul>
            </div>);
        },
        "COOKIE_POLICY_CONTACT": () => {
            return (<div className="Text500__5" style={{ textAlign: 'justify' }}>
                <p>Per qualunque domanda: <a href="mailto:info@labh9.eu">info@labh9.eu</a></p>
            </div>)
        },
        "UNABLE_TO_TRANSCRIBE": () => {
            return `Impossibile trascrivere le immagini fornite`;
        },
        "APPROVAL": () => {
            return `Approvazione`;
        },
        "NO_INFORMATION_CLASS": () => {
            return `Non sono state fornite informazioni sulla classe`;
        },
        "PRIVACY_POLICY_REGISTER": () => {
            return (
                <p className='Text500__6'>e <strong><a href="/it/privacy_policy" target='_blank' rel="noopener noreferrer" >privacy</a></strong></p>
            );
        },
        "PRIVACY_POLICY": () => {
            return "Privacy";
        },
        "COUNTRY_REQUIRED": () => {
            return `Dovete scegliere un paese!`;
        },
        "EMAIL_SENT": () => {
            return `È stata inviata un'e-mail per la modifica della password, controllare la posta in arrivo.`;
        },
        "ENTER_EMAIL_FORGOT_PASSWORD": () => {
            return (<p>Inserite <strong>l'indirizzo e-mail</strong> associato al vostro account e vi invieremo un link per reimpostare la vostra <strong>password</strong>.</p>);
        },
        "PASSWORD_RESET": () => {
            return `Recupero password`;
        },
        "REQUEST": () => {
            return `Invia richiesta`;
        },
        "REQ_PROFESSOR": () => {
            return `Sei sicuro di voler chiedere l’aggiornamento del tuo account utente? Verrà attivata una procedura di verifica e potrai essere contattato dal nostro back-office`;
        },
        "CONFIRM_ROLE_PROFESSOR": () => {
            return `Passa ad un account per professori`;
        },
        "PROMO_CODE": () => {
            return `Codice promo`;
        },

        "ERROR_CREATE_TEST": () => {
            return "Seleziona tutti i parametri!";
        },

        // About anna screen
        "ABOUTANNA": () => {
            return `Informazioni su ANNA`;
        },

        "ABOUTANNA_WHOISANNA": () => {
            return `Chi è ANNA?`;
        },

        "ABOUTANNA_WHOISANNA_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> è un tutor digitale basato su avanzate tecnologie di intelligenza artificiale.
                    <br></br>

                    <strong>ANNA</strong> ti puó aiutare nello studio ottimizzando il tuo tempo e semplificando l’apprendimento con mappe mentali, chat avanzate e simulazioni d’esame.
                    <br></br>

                    <strong>ANNA</strong> utilizza solo le tue fonti (libri, video, documenti) per aiutarti nello studio, è dunque affidabile e sicura.
                    <br></br>

                    <strong>ANNA</strong> è una compagna di studi ideale e puoi provarla gratuitamente.

                </p>
            );
        },
        "ABOUTANNA_HOWITWORKS_TITLE": () => {
            return `Come funziona ANNA?`;
        },
        "ABOUTANNA_HOWITWORKS": () => {
            return `Come funziona?`;
        },

        "ABOUTANNA_HOWITWORKS_ANSWER": () => {
            return (
                <div>
                    <p>Scegli la tua lingua preferita</p>

                    <br></br>
                    <div className='image_div'>
                        <img className="about_image" alt="Language selector" src={changeLanguage}></img>
                    </div>
                    <p>Non preoccuparti, potrai cambiarla in ogni momento e le lingua disponibili aumenteranno nel tempo.

                        <br></br>

                        Carica i tuoi documenti su <strong>ANNA</strong>. Puoi usare ogni tipo di file, pdf, word, ebook, excel, power point oppure file multimediali come immagini, video o audio.
                    </p>

                    <div className='image_div'>
                        <img className="about_image" alt="File Uploading" src={fileUpload}></img>
                    </div>

                    <p> Puoi scattare le foto ai tuoi libri o ai tuoi appunti </p>

                    <div className='image_div'>
                        <img className="about_image" alt="ImageUploading" src={imgUpload}></img>
                    </div>


                    <p> oppure registrare la tua voce.</p>

                    <div className='image_div'>
                        <img className="about_image" alt="Audio uploading" src={audioUpload}></img>
                    </div>

                    <p> Puoi organizzare i tuoi contenuti in cartelle oppure estrarre dai documenti le sole pagine che ti interessano.</p>

                    <div className='image_div'>
                        <img className="about_image" alt="Create folder" src={folderExample}></img>
                    </div>

                    <p>Non preoccuparti della traduzione, <strong>ANNA</strong> utilizza sempre la tua lingua preferita.</p>

                </div>
            );
        },

        "ABOUTANNA_Q&A_TITLE": () => {
            return `Question & Answer`;
        },

        "WHATISQ&A": () => {
            return `Che cos'è la funzione Question and answer?`;

        },

        "WHATISQ&A_ANSWER": () => {
            return (
                <p>
                    Con questa funzione puoi sfruttare la potenza della  <strong> AI generativa</strong> per chattare con <strong>ANNA</strong> utilizzando come base di conoscenze i tuoi stessi documenti.

                    <br></br>

                    <strong>ANNA</strong> non si limita a rispondere alle tue domande, fa molto di più.

                    <br></br>

                    Utilizzando esclusivamente i tuoi file (libri, note, immagini, video, audio) <strong>ANNA</strong> è in grado di creare riassunti, estrarre e rielaborare informazioni, produrre tabelle, comparare dati o generare nuovi contenuti in base alle tue richieste.

                    <br></br>

                    Non porre limiti a cosa puó fare <strong>ANNA</strong>.


                </p>

            );
        },

        "MINDMAP_USE_QUESTION": () => {
            return `Cosa sono le Mindmap?`;

        },

        "MINDMAP_USE_ANSWER": () => {
            return (
                <p>
                    Le mappe mentali sono un ottimo strumento per studiare argomenti vasti o complessi.

                    <br></br>

                    <strong>ANNA</strong>  crea per te le mappe mentali di ogni documento o cartella organizzando i contenuti in una forma grafica utile per un apprendimento rapido e per la memorizzazione degli elementi chiave.

                    <br></br>

                    Puoi navigare la mappa espandendo i nodi e visualizzando i riassunti di ogni argomento.

                    <br></br>

                    Puoi condividere, stampare ed esportare la tua mappa con un click.
                    <div className='image_div'>
                        <img src={mindMap} alt="mindmap" className="about_image"></img>
                    </div>
                </p>

            );

        },

        "HOW_TO_USE_TEST": () => {
            return `Come funzionano i test di ANNA?`;
        },

        "HOW_TO_USE_TEST_ANSWER": () => {
            return (
                <p>
                    Con <strong>ANNA</strong> puoi simulare un esame per verificare lo stato delle tue conoscenze.
                    <br></br>
                    Scegli il documento o la cartella e crea un test di autovalutazione.
                    <br></br>
                    Puoi personalizzare il tuo test indicando il numero di domande e il tipo di esame, con risposte chiuse o aperte.
                    <br></br>
                    Rispondi alle domande e controlla il risultato. <strong>ANNA</strong> ti spiegherà eventuali errori indicandoti la risposta corretta e le pagine dei tuoi testi da rileggere.
                    <br></br>
                    Allenati con i test di <strong>ANNA</strong> e preparati per il prossimo compito in classe, interrogazione od esame.

                </p>

            )

        },
        "HOW_TRY_ANNA": () => {
            return `Come posso provare ANNA?`;
        },

        "HOW_TRY_ANNA_ANSWER": () => {
            return (
                <p>
                    Puoi provare <strong>ANNA</strong> gratis. Basta registrarsi oppure accedere con Google o Facebook.
                    <br></br>
                    Potrai provarlo con i primi cinque documenti, con un massimo di 50 domande e 5 test.
                    <br></br>
                    Se <strong>ANNA</strong> risponde alle tue esigenze potrai acquistare un abbonamento settimanale o mensile.

                </p>

            )
        },

        "HOW_MUCH": () => {
            return `Quanto costa ANNA?`;
        },

        "HOW_MUCH_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> è gratis per i primi  cinque documenti
                    <br></br>
                    Hai a disposizione, con la prova gratuita, un massimo di 50 domande e 5 test.
                    <br></br>
                    Puoi  consultare il nostro <a href='/catalogue' >catalogo</a> per vedere le nostre offerte e confrontare i diversi piani di abbonamento
                    <br></br>

                    Puoi  acquistare gli abbonamenti utilizzando PayPal e le principali carte di credito.
                    <br></br>

                    Puoi interrompere il rinnovo automatico in qualunque momento.
                    <br></br>

                    Le scuole e gli insegnanti possono godere di particolari policy di sconto, contattaci per ulteriori dettagli.

                </p>

            )
        },

        "ANNA_LAB": () => {
            return `Che cos'è  ANNA-LAB?`;
        },

        "ANNA_LAB_ANSWER": () => {
            return (
                <p>
                    Se vuoi essere un beta tester <strong>ANNA-LAB</strong> è il posto ideale.
                    <br></br>
                    Potrai provare le nuove funzioni ancora in sviluppo e interagire con i nostri developers.

                </p>

            )
        },

        "NOACCOUNT": () => {
            return 'Non hai un account?'
        },

        "REGISTERFREE": () => {
            return 'Registrati gratis'

        },
        "CANCEL_SUBSCRIPTION_MODAL": () => {
            return "È sicuro di voler annullare l'abbonamento? Sarà possibile utilizzare l'abbonamento fino alla data di scadenza."
        },
        "FILE_UPLOADED": () => {
            return "Il file è stato caricato"
        },
        "FILE_UPLOADED_SUCCESS": () => {
            return "Documento caricato con successo"
        },
        "ERROR_ACCESSING_MICROPHONE": () => {
            return "Errore durante l'accesso al microfono"
        },
        "FAILED_TO_GET_PAYMENTS": () => {
            return "Impossibile ottenere i pagamenti"
        },
        "SUCCESS": () => {
            return "Successo";
        },


        "QUICKCARDS_SESSION_TITLE": () => {
            return `QuickCards`;
        },
        "QUICKCARDS_SESSION": () => {
            return `Posso creare per te delle quickcards che ti aiuteranno ad assmilare i concetti più importanti con dei riassunti strepitosi`;
        },

        "HOW_ANNA_WORK": () => {
            return `Guarda come funziona`;
        },
        "BLOGS":()=>{
            return `Blogs`;
        }        ,
        "CONNECTING_CHAT_AI_ENGINE": () => {
            return `Il tuo chat bot dedicato di ANNA si sta avviando. Attendi qualche secondo.`;
        },
        "CONNECTED_CHAT_AI_ENGINE": () => {
            return `Il tuo chat bot di ANNA è ora pronto.`;
        },
        "PUBLIC_CARDS_SCIENCE_TOPIC": () => {
            return `Scienza`;
        },
        "PUBLIC_CARDS_HISTORY_TOPIC": () => {
            return `Storia`;
        },
        "PUBLIC_CARDS_ARTS_TOPIC": () => {
            return `Arte`;
        },
        "PUBLIC_CARDS_SOCIETY_TOPIC": () => {
            return `Società`;
        },
    }
}

export { getText };