import { React, useState, useContext, useEffect } from 'react';
import * as labels from "../components/localized/labels";
// Cell renderer component
const ApprovalCellRenderer = ({ data, classroomCode, onAccept, getStudent, onRemove }) => {
    const [approved, setApproved] = useState(null);

    useEffect(() => {
        const fetchApprovalStatus = async () => {
            const result = await getStudent(data, classroomCode);
            setApproved(result);
        };

        fetchApprovalStatus();
    }, [data, classroomCode]);

    return (
        <div className="approval-buttons-container">
            {approved ? (
                <button onClick={() => { if (onRemove) { onRemove(data, classroomCode) } }} className="btn btn-danger">{labels.getText()["REMOVE"]()}</button>
            ) : (
                <>
                    <button onClick={() => { if (onAccept) { onAccept(data, classroomCode) } }} className="btn btn-success">{labels.getText()["ACCEPT"]()}</button>
                    <button onClick={() => { if (onRemove) { onRemove(data, classroomCode) } }} className="btn btn-danger">{labels.getText()["DECLINE"]()}</button>
                </>
            )}
        </div>
    );
};


const RemoveProfessoCellRender = ({data,classroomCode,onRemove}) => {
    // const [approved, setApproved] = useState(null);

    // useEffect(() => {
    //     const fetchApprovalStatus = async () => {
    //         const result = await getStudent(data, classroomCode);
    //         setApproved(result);
    //     };

    //     fetchApprovalStatus();
    // }, [data, classroomCode]);

    return (
        <div className="approval-buttons-container">
            <button onClick={() => { if (onRemove) { onRemove(data, classroomCode) } }} className="btn btn-danger">{labels.getText()["REMOVE"]()}</button>
        </div>
    );
};

export { ApprovalCellRenderer, RemoveProfessoCellRender };