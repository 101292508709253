import { useParams } from 'react-router-dom';
import art_image from "../assets/img/Cards/art.jpeg";
import history_image from "../assets/img/Cards/history.webp";
import science_image from "../assets/img/Cards/science.jpeg";
import society_image from "../assets/img/Cards/society.jpg";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import * as labels from "../components/localized/labels";
import UserContext from '../components/User_Context';
import { React, useState, useEffect, useContext } from 'react';
import * as api from '../api/apiConnector';

const Public_QuickCards_Screen = (props) => {
    const [cards, setCards] = useState(null);
    const { topic, language } = useParams();
    let { user } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            get_images();
        })();
    }, [cards]);

    const get_images = async (params) => {
        let _pictures = window.$(`img[data-topic-id]`).toArray();
        for (var p of _pictures) {
            p = window.$(p);

            var imageData = (await api.sendMessage("getPublicCardPicture", {
                body: {
                    topicId: p.attr("data-topic-id")
                }
            }));
    
            if (imageData?.success) {
              
                p.attr("src", `data:image/png;base64, ${imageData.imageData}`);
            }
        }
    }

    const draw_card = (params) => {
        const { card } = params;
        const topic_id = card.topics && card.topics.length > 0 ? card.topics[0]._id : null;

        return (
            <a href={`/${language}/public/quickcards/${card.documentId}`}>
                <div className="card" style={{ marginBottom: 10 }}>
                    <img className="card-img-top" data-topic-id={topic_id}></img>
                    <div className="card-body">
                        <h5 className="card-title">{card.title}</h5>
                    </div>

                </div>
            </a>
        );
    }

    const draw_cards = (params) => {
        const { cards } = params;

        return (<div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))" }}>
            {cards.map(x => {
                return draw_card({ card: x });
            })}
        </div>);
    }

    const draw_topic_box = (params) => {
        const { topic_id, img, classification } = params;
        return (
            <a href={`/${language}/public/quickcards/list/${classification}`}>
                <div className="card" style={{ marginBottom: 10 }}>
                    <img src={img} className="card-img-top"></img>
                    <div className="card-body">
                        <h5 className="card-title">{labels.getText()[topic_id]()}</h5>
                    </div>

                </div>
            </a>
        );
    }

    const draw_topics_list = (params) => {
        return (<div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))" }}>
            {draw_topic_box({ topic_id: "PUBLIC_CARDS_SCIENCE_TOPIC", img: science_image, classification: "science" })}
            {draw_topic_box({ topic_id: "PUBLIC_CARDS_HISTORY_TOPIC", img: history_image, classification: "history" })}
            {draw_topic_box({ topic_id: "PUBLIC_CARDS_ARTS_TOPIC", img: art_image, classification: "art" })}
            {draw_topic_box({ topic_id: "PUBLIC_CARDS_SOCIETY_TOPIC", img: society_image, classification: "society" })}
        </div>);
    }

    const list_cards = async (params) => {
        const { topic } = params;

        var cards = (await api.sendMessage("listPublicCards", {
            body: {
                topic
            },
            user: user,
        }));

        return cards;
    }

    const draw_body = (params) => {
        switch (topic) {
            case "science":
            case "arts":
            case "history":
            case "society":
                {
                    if (!cards) {
                        list_cards({ topic }).then((c) => {
                            setCards(c);
                        });
                        return (null);
                    }
                    else {
                        return draw_cards(cards);
                    }
                }
                break;
            default:
                {
                    return draw_topics_list();
                }
        }
    }

    return (<div className='wrap'>
        <div className="container py-5">
            <div>
                <div className="wow fadeIn " data-wow-delay="0.1s">
                    <div className="stack-title">
                        {getBreadcrumb({
                            page: "public_quickcards",
                            topic
                        })}
                        {SetMenu({ user: user })}
                        <h1 className="document-boost">QUICK CARDS</h1>
                    </div>
                    {draw_body()}
                </div>
            </div>
        </div>
    </div>
    )
}

export default Public_QuickCards_Screen;