import { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import JSMindMM from '../components/JSMindMM';
import * as api from '../api/apiConnector';
import UserContext from '../components/User_Context';
import { useNavigate } from 'react-router-dom';
import waiting from '../assets/img/03-42-11-849_512.webp';
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import * as labels from "../components/localized/labels";
import logo from '../logoblack.png';
//import Mermaid from '../components/Mermeid';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Helmet } from 'react-helmet';
import { NewspaperRounded, WindowSharp } from '@mui/icons-material';
import MindMap_Presentation from '../components/MindMap_Presentation';
import ApprovalCellRenderer from '../components/ApprovalCellRenderer';
import ExportPrintButton from '../components/export/exp_buttons/ExportPrintButton';
import { Modal } from 'react-bootstrap';
import ExportDocxButton from '../components/export/exp_buttons/ExportDocxButton';
import ExportPptxButton from '../components/export/exp_buttons/ExportPptxButton';
import jsPDF from "jspdf";
import anna_logo from "../assets/Logo.svg";

var options = {
  container: "jsmind_container",
  theme: 'greensea',
  editable: true,
  mode: 'full',
  view: {
    draggable: true,
  },
  support_html: true,
  zoom: {
    min: 0.001,
    max: 100,
    step: 0.001,
  }
};



function Mindmap_Screen() {
  const [document, setDocument] = useState(null);
  let { user } = useContext(UserContext);
  user = user ? JSON.parse(user) : null;
  const { id, topic, printId, folderId, professorclassroomId, classroomId, language } = useParams();
  const navigate = useNavigate();
  const mindGraph = useRef();
  const [visibleItem, setVisibleItem] = useState("map");
  const [activeTopic, setActiveTopic] = useState(null);

  //ui map
  const [mind, setMind] = useState(null);

  let _documentLink = folderId ? `/${language}/folder/${folderId}` : `/${language}/document/${id}`;
  if (professorclassroomId) {
    _documentLink = folderId ? `/${language}/professor/folder/classroom/${folderId}/${professorclassroomId}` : `/${language}/professor/document/classroom/${id}/${professorclassroomId}`;
  }
  else if (classroomId) {
    _documentLink = folderId ? `/${language}/folder/classroom/${id}/${classroomId}` : `/${language}/document/classroom/${id}/${classroomId}`;
  }

  //db map
  const [map, setMap] = useState(null);
  const [preiousMap, setPreviousMap] = useState(null);
  let _mapNodeCounter = 0;

  const downloadPdf = async (pictureData) => {
    const pdf = new jsPDF('l');
    const pageHeight = pdf.internal.pageSize.height;
    pdf.addImage(pictureData, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
    const data = pdf.output('blob');
    var pdfURL = window.URL.createObjectURL(data);
    var tempLink = window.document.createElement('a');
    tempLink.href = pdfURL;
    tempLink.setAttribute('download', `${document.title ?? document.fileName}.mindmap.pdf`);
    tempLink.click();
  };

  function _htmlEncode(text) {
    const divElement = window.document.createElement("div");
    divElement.innerText = text;
    return divElement.innerHTML || "";
  }

  function _htmlDecode(html) {
    const divElement = window.document.createElement("div");
    divElement.innerHTML = html;
    return divElement.innerText || "";
  }

  function _transformMapNode(sourceNodes, destNodes, level) {
    var iCounter = 1;

    const nodeColor = ["#155263", "#ff6f3c", "#ff9a3c", "#5e63b6", "#247291", "#7fa99b", "#f95959", "#5b446a"];

    if (sourceNodes) {
      for (var s of sourceNodes) {
        var node = {
          id: `${level}.${iCounter}.${_mapNodeCounter}`,
          width: 500,
          topic: `<div><div style="margin-bottom: 0.5em;"><i class="${s.icon} fa-2x"></i><hr/></div><div>${_htmlEncode(s.topic)}</div></div>`,
          children: [],
          direction: level == 1 ? (iCounter % 2 == 0 ? "left" : "right") : null,
          'background-color': level < nodeColor.length ? nodeColor[level - 1] : nodeColor[nodeColor.length - 1]
        };

        //level == 1 ? (set_node_color(s.node.id, "#006400", "black")) : 
        //(level == 2 ? set_node_color(s.node.id, "#006400", "black") : set_node_color(s.node.id, "#006400", "black"))

        if (node.topic !== undefined) {
          destNodes.push(node);
          iCounter++;
          _mapNodeCounter++;
        }

        _transformMapNode(s.linkedTopics, node.children, level + 1);
      }
    }
  }

  function _countChildren(node) {
    let output = 0;

    if (node.children) {
      for (var c of node.children) {
        output += _countChildren(c) + 1;
      }
    }

    //console.log(`${node.id} ${node.topic} output ${output}`);
    return output;
  }

  //transform a node to markdown code
  function _getNodeMap3(level, node) {
    let output = [];

    let _span = window.$("<span/>");
    _span.text(node.topic);
    _span.css("cursor", "pointer");
    _span.attr("data-code", node.code);
    _span.attr("onclick", `document.location.hash='detail:${node.code}'`);
    let _html = window.$("<div/>").append(_span).html();


    //level, icon, title
    output.push(`${"#".repeat(level)} ${node.icon ? `<i class="${node.icon}"></i>` : ""} ${_html}`);

    if (node.linkedTopics && node.linkedTopics.length > 0) {
      for (var c of node.linkedTopics) {
        let _childrenNodes = _getNodeMap3(level + 1, c);
        for (var cn of _childrenNodes) {
          output.push(cn);
        }
      }
    }

    return output;
  }

  //transform a map to markdown code
  function _transformMap3(map) {
    //will be the markdown code used
    //by the map
    let output = [];

    //set the map title
    map.title = document.title ? document.title : document.fileName;

    //use the root node if available from the map
    //(checking if the map starts with a single root node)
    if (map.topics && map.topics.length == 1) {
      map.topics = map.topics[0].linkedTopics;
      map.title = map.topics[0].topic;
    }

    //setup the main settings
    output.push(`---`);
    output.push(`title: ${map.title}`);
    output.push(`markmap:`);
    //max node width (long text will be wrapped) 
    output.push(`  maxWidth: 350`);
    //max number of levels initially expanded
    output.push(`  initialExpandLevel: 4`);
    output.push(`  zoom: true`);
    output.push(`---`);

    //initial loop on children
    for (var t of map.topics) {
      let _item = _getNodeMap3(1, t);
      for (var i of _item) {
        output.push(i);
      }
    }

    return output.join("\n");
  }

  function _getNodeMap2(level, topic) {
    let output = [];

    let _topic = topic.topic;
    _topic = _topic.replace("(", "");
    _topic = _topic.replace(")", "");
    _topic = _topic.replace("[", "");
    _topic = _topic.replace("]", "");

    output.push(`${"\t".repeat(level + 1)}(${_topic})`);
    if (topic.icon) {
      output.push(`${"\t".repeat(level + 1)}::icon(${topic.icon})`);
    }

    if (topic.linkedTopics && level < 2) {
      for (var l of topic.linkedTopics) {
        let _item = _getNodeMap2(level + 1, l);
        for (var i of _item) {
          output.push(i);
        }
      }
    }

    return output;
  }

  function _transformMap2(map) {
    if (map.topics && map.topics.length == 1) {
      map.topics = map.topics[0].linkedTopics;
    }

    let output = [];
    output.push(`%%{init: {'theme': 'dark', 'config': {'handdrawn': true}}}%%`);
    output.push("mindmap");
    output.push(`{{${document.title ? document.title : document.fileName}}}`);

    for (var t of map.topics) {
      let _item = _getNodeMap2(1, t);
      for (var i of _item) {
        output.push(i);
      }
    }

    return output.join("\n");
  }

  function _transformMap(map) {
    _mapNodeCounter = 0;

    var root = {
      id: "root",
      topic: `<div style="min-width: 25em;"><div style="margin-bottom: 0.5em;"><i class="fa fa-house fa-2x"></i><hr/></div><div>${_htmlEncode(document.title ? document.title : document.fileName)}</div></div>`,
      children: []
    }

    _transformMapNode(map.topics, root.children, 1);

    //normalize the hierarchy
    let _removedLevels = 0;
    while (root.children && root.children.length == 1 && _removedLevels < 2
      && root.children[0].children && root.children[0].children.length > 0
    ) {
      root = root.children[0];
      if (root.children) {
        let _direction = "right";
        for (var c of root.children) {
          c.direction = _direction;
          _direction = _direction == "left" ? "right" : "left";
        }
      }
      _removedLevels++;
    }

    //second normalize
    let _children = root.children;
    _children.sort((a, b) => {
      return _countChildren(b) - _countChildren(a);
    });

    let _direction = "right";
    for (var c of root.children) {
      c.direction = _direction;
      _direction = _direction == "left" ? "right" : "left";
    }

    return root;
  }

  function _drawLoading(map) {
    let _div = window.$("#loading_details");
    _div.html("");

    const _drawNodes = (children, container) => {
      if (children && children.length > 0) {
        let _ul = window.$(`<ul class="list-group list-group-flush small"/>`);
        container.append(_ul);
        for (var c of children) {
          let _li = window.$(`<li class="list-group-item"/>`);
          _ul.append(_li);
          _li.append(window.$("<span/>").text(c.topic));
          _drawNodes(c.topics ?? c.linkedTopics, _li);
        }
      }
    }

    _drawNodes(map.topics, _div);

  }

  async function refreshMindMap() {
    if (!document) {
      var doc = folderId ? (await api.sendMessage("getFolder", {
        body: {
          id: folderId,
          classroom: professorclassroomId ?? classroomId
        },
        user: user,
      })).folder : (await api.sendMessage("getDoc", {
        body: {
          id: id ?? printId,
          classroom: professorclassroomId ?? classroomId
        },
        user: user,
      })).document;
      if (doc) {
        setDocument(doc);
      }
      else {
        navigate(`/${language}`);
      }
    }
    else {
      let _map = null;

      // continue looping while map is not fully created
      while (!_map || _map.status == "indexing") {
        var result = (await api.sendMessage("getMindMapGemini", {
          body: {
            docId: id ? id : printId,
            folderId: folderId,
            topic: topic,
            professorclassroomId,
            classroomId
          },
          user: user,
        }));

        _map = result.map;

        if (_map.status == "error") {
          withReactContent(Swal).fire({
            title: <i>{labels.getText()["ERROR"]()}</i>,
            icon: "error",
            text: labels.getText()["ERROR_WHILE_CREATING_MINDMAP"](),
            showConfirmButton: true,
            allowOutsideClick: false,
            showCloseButton: true,
          }).then((b) => {
            navigate(`/${language}/document/${id}`);
          });
          return;
        }

        setMap(_map);

        // if map not yet done
        if (_map.status == "indexing") {
          _drawLoading(_map);
        }

        await new Promise(resolve => setTimeout(resolve, 5000));

      }

      let _mind = _transformMap3(_map);

      //_uiMap.children = [_uiMap.children[0], _uiMap.children[0]];
      // set mindmap state variable
      setMind(_mind);
    }
  }

  function _linearizeNode(node, parent) {
    var output = [];
    output.push({ node: node, parent: parent });
    if (node.children) {
      for (var c of node.children) {
        var _subs = _linearizeNode(c, node);
        for (var s of _subs) {
          output.push(s);
        }
      }
    }

    return output;
  }

  function _buildPaths(topics) {
    for (var l of topics) {
      let _path = [];
      let _current = l;

      while (_current != null) {
        _path.push(_current.topic);
        _current = _current.parent ? topics.find(x => x.topic.code == _current.parent.code) : null;
      }
      l.path = _path.reverse().map(x => x.topic).join(" > ");
    }
  }

  function _linearizeMap(parent, topics) {
    var output = [];
    for (var t of topics) {
      var _newTopic = {
        parent: parent,
        topic: t
      };
      output.push(_newTopic);

      var _subs = _linearizeMap(t, t.linkedTopics);
      for (var s of _subs) {
        output.push(s);
      }
    }

    if (!parent) {
      _buildPaths(output);
    }

    return output;
  }

  //Add_Library(process.env.PUBLIC_URL + '/js/jsmind.js');

  const [mindContent, setMindContent] = useState(null);

  const [mindLastUpdate, setMindLastUpdate] = useState(new Date());

  useEffect(() => {
    //console.log("useEffect");

    if (mind) {
      setTimeout(() => {
        window.$(".mm-toolbar").hide();
      }, 500);
    }

    window.$(window).on("hashchange", function () {
      if (window.location.hash.startsWith("#detail:")) {
        let _code = window.location.hash.split(":")[1];
        setActiveTopic(_code);
      }
      else {
        setActiveTopic(null);
      }

      window.$(".linkFooter").hide();

    });
  }, [mindLastUpdate, mindContent, activeTopic, mind]);

  useEffect(() => {
    //console.log("useEffect refreshMindMap");
    (async () => {
      refreshMindMap();
      window.$(window).css("overflow", "hidden");
      window.$("body").css("overflow", "hidden");
    })();
  }, [document]);


  function The_Show() {
    switch (visibleItem) {
      case "map": {
        return Map();
      }
        break;
      case "presentation": {
        return Presentation();
      }
        break;
    }
    return (null);
  }

  function openFullscreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  function Presentation() {
    return (<MindMap_Presentation id="presentation" map={map} documentLink={_documentLink} onMap={() => {
      setVisibleItem("map");
    }}></MindMap_Presentation>);
  }

  function Waiting() {
    return (<div>
      <div id='mindmap-close' style={{
        position: "fixed",
        right: 50,
        top: 20,
        zIndex: 10001
      }}>
        <a href={_documentLink} className="btn btn-lg btn-primary btn-lg-square"><i className="fa fa-times-circle"></i></a>
      </div>

      <div className="markmap" style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10000,
        backgroundColor: "white",
        padding: "2em"
      }}>
        <h1>{labels.getText()["LOADING"]()}</h1>
        <div id="loading_details"></div>
      </div>
    </div>);
  }

  const MindMap_Topic_Modal = (code) => {

    const _tellMeMore = (props) => {
      const { topic } = props;

      if (id) {
        return (<a href={`/${language}/qa/${id}/${encodeURI(topic)}`}>{labels.getText()["TELL_ME_MORE"]()}</a>);
      } else if (folderId) {
        return (<a href={`/${language}/folder/${folderId}/qa/${encodeURI(topic)}`}>{labels.getText()["TELL_ME_MORE"]()}</a>);
      }
    }


    if (activeTopic) {
      let _topic = _linearizeMap(null, map.topics).find(x => x.topic.code == activeTopic);

      if (_topic) {
        return (<Modal scrollable={true} style={{
          zIndex: 10001
        }} show={true} onHide={() => {
          window.location.hash = '';
        }}>
          <Modal.Header closeButton>
            <Modal.Title>
              {_topic.topic.topic}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <small>{_topic.path}</small>
            </p>
            <p>
              {_tellMeMore({ topic: _topic.topic.topic })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" type="button" onClick={() => {
              setActiveTopic(null);
            }}>
              {labels.getText()["CLOSE"]()}
            </button>

          </Modal.Footer>
        </Modal>);
      }
      else {
        return (null);
      }
    }
    else {
      return (null);
    }
  };

  const print = async () => {
    window.$("#mind-map-printing").prop("hidden", null);
    var imageResult = await api.sendMessage("printMindMap", {
      body: {
        id: map.id,
        url: window.document.location.href
      },
      user: user,
    });

    var imgData = `data:image/png;base64,${imageResult.printData.base64}`;

    downloadPdf(imgData);
    window.$("#mind-map-printing").prop("hidden", true);
  }


  function Map() {


    return (<div>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/markmap-autoloader@latest"></script>
      </Helmet>
      {
        (MindMap_Topic_Modal())
      }
      {!user?.printMode ? (<>
        {window.ReactNativeWebView ? (null) : (
          <div id='mindmap-close' style={{
            position: "fixed",
            right: 50,
            top: 20,
            zIndex: 10001
          }}>
            <a href={_documentLink} className="btn btn-lg btn-primary btn-lg-square"><i className="fa fa-times-circle"></i></a>
          </div>
        )}
        <div style={{
          position: "fixed",
          right: 110,
          top: 20,
          zIndex: 10001
        }}>
          <a href="#" onClick={() => {
            setVisibleItem("presentation");
          }} className="btn btn-lg btn-primary btn-lg-square"><i className="fa fa-video"></i></a>
        </div>
      </>
      ) : (null)}
      <div className="markmap" style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10000,
        backgroundColor: "white"
      }}>
        <textarea value={mind} style={{ visibility: "hidden", display: "none" }}></textarea>
      </div>
      {!user?.printMode ? (<>
        <ExportPptxButton map={map} />
        <ExportDocxButton map={map} />
        <ExportPrintButton map={map} onPrint={print} /></>) : (null)}
    </div>);
  }

  return (<div className="App App-body">
    {SetMenu({ user: user })}
    {getBreadcrumb({ page: "mindmap", id: id, name: document ? document.fileName : "..." })}

    <div>
      {mind ? (The_Show()) : (Waiting())}
    </div>
    <div style={{
      position: "fixed",
      left: 10,
      bottom: 10
    }}>
      ANNA https://anna.labh9.ai
      <img src={anna_logo}></img>
    </div>
    <div id="mind-map-printing" style={{
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      zIndex: 20002,
      backgroundColor: "lightblue",
      padding: "2em",
      opacity: .7
    }} hidden>
      <div style={{
        position: "relative",
        top: "50%",
        msTransform: "translateY(-50%)",
        WebkitTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        fontSize: "5em"
      }}>
        <i className="fa fa-print fa-2x" ></i> Printing
      </div>

    </div>
  </div>)

}

export default Mindmap_Screen;